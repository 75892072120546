import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppContainer from './containers/AppContainer';
import reportWebVitals from './reportWebVitals';

import { configureStore, Middleware } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';

import { createLogger } from 'redux-logger'

import appReducer from './store/reducers';
import initialState from './store/initial';
import { Provider } from 'react-redux';
import './i18n';
import i18n from 'i18next';
import { configGetFetch, libraryGetFetch, uiGoTo, uiSetCode } from './store/actions';


let applicationStore = configureStore({reducer: appReducer, preloadedState: initialState, middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(thunk)
});

let loggerMiddleware : Middleware|null = null;
if(process.env.NODE_ENV !== 'production') {
    loggerMiddleware = createLogger();
    applicationStore = configureStore({reducer: appReducer, preloadedState: initialState, middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(thunk).concat(loggerMiddleware as Middleware)
    });
}

const search = window.location.search;
const params = new URLSearchParams(search);
const code = params.get('code');
const lang = params.get('lang');
const route = params.get('route');

if(lang !== null) {
    i18n.changeLanguage(lang);
}
let dispatch = applicationStore.dispatch as any;
if(code !== null) {
    dispatch(uiSetCode(code));
    dispatch(configGetFetch());
}

if(route !== null) {
    dispatch(uiGoTo(route));
}

dispatch(libraryGetFetch());

ReactDOM.render(
    <React.StrictMode>
        <Provider store={applicationStore}>
            <AppContainer />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
