/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GroupProps = JSX.IntrinsicElements['group'];

type GLTFResult = GLTF & {
  nodes: {
    Cargo1: THREE.Mesh;
    Cargo: THREE.Mesh;
    Leer: THREE.Mesh;
    Cargo_Schatten: THREE.Mesh;
    Boden: THREE.Mesh;
    Links: THREE.Mesh;
    Rechts: THREE.Mesh;
    Beide: THREE.Mesh;
  };
  materials: {
    Hose_Leer: THREE.MeshStandardMaterial;
    Mat: THREE.MeshStandardMaterial;
    Boden: THREE.MeshStandardMaterial;
    ["Mat.1"]: THREE.MeshStandardMaterial;
  };
};
interface ModelProps extends GroupProps {
  children?: React.ReactNode;
  leftIsAttached: boolean;
  rightIsAttached: boolean;
}

const debug = false;

export default function Model(props: ModelProps) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF("./models/Hose.glb") as unknown as GLTFResult;

  const leftBadge = useGLTF("./models/Lappen_Links.glb") as any;
  const rightBadge = useGLTF("./models/Lappen_Rechts.glb") as any;

  //leftBadge.materials.Mat.map.repeat = new THREE.Vector2(3, 3);
  //rightBadge.materials.Mat.map.repeat = new THREE.Vector2(3, 3);

  return (
    <group ref={group} {...props} dispose={null}>
      {props.leftIsAttached && 
        <primitive object={leftBadge.scene} />
      } {props.rightIsAttached && 
        <primitive object={rightBadge.scene} />
      }
      <group name="AuxScene">
        <group
          name="D34A6C51-1622-42F7-ACDE-E3F055F9AFAC"
          userData={{ name: "D34A6C51-1622-42F7-ACDE-E3F055F9AFAC" }}
        >
          <mesh
            name="Cargo"
            geometry={nodes.Cargo.geometry}
            material={materials.Hose_Leer}
            position={[0, 0.4, 0]}
            rotation={[-Math.PI / 2, -1.396, -Math.PI / 2]}
            userData={{
              name: "Cargo",
              associations: { meshes: 0, primitives: 0, nodes: 0 },
              variantData: {},
            }}
          >
            <group
              name="Cargo_Links"
              position={[0.186, -0.086, -0.028]}
              rotation={[0.175, Math.PI / 2, 0]}
              scale={[1.5, 1.5, 1.5]}
              userData={{ name: "Cargo_Links", associations: { nodes: 4 } }}
            >
              <group
                name="Hose_L_0_0"
                position={[-0.055, 0.057, -0.031]}
                rotation={[0, -0.87, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_0", associations: { nodes: 5 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_1"
                position={[-0.051, 0.058, -0.026]}
                rotation={[0, -0.889, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_1", associations: { nodes: 6 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_2"
                position={[-0.043, 0.059, -0.015]}
                rotation={[0, -0.744, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_2", associations: { nodes: 7 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_3"
                position={[-0.033, 0.06, -0.009]}
                rotation={[0, -0.468, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_3", associations: { nodes: 8 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_4"
                position={[-0.023, 0.06, -0.005]}
                rotation={[0, -0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_4", associations: { nodes: 9 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_5"
                position={[-0.014, 0.06, -0.003]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_5", associations: { nodes: 10 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_6"
                position={[-0.005, 0.06, -0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_0_6", associations: { nodes: 11 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_7"
                position={[0.005, 0.06, -0.003]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_7", associations: { nodes: 12 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_8"
                position={[0.014, 0.06, -0.005]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_8", associations: { nodes: 13 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_9"
                position={[0.023, 0.06, -0.009]}
                rotation={[0, 0.202, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_9", associations: { nodes: 14 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_10"
                position={[0.033, 0.06, -0.009]}
                rotation={[0, 0.365, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_10", associations: { nodes: 15 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_11"
                position={[0.042, 0.06, -0.017]}
                rotation={[0, 0.869, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_11", associations: { nodes: 16 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_12"
                position={[0.043, 0.06, -0.019]}
                rotation={[0, 1.053, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_0_12", associations: { nodes: 17 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_0"
                position={[-0.06, 0.046, -0.037]}
                rotation={[0, -0.873, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_0", associations: { nodes: 18 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_1"
                position={[-0.051, 0.051, -0.026]}
                rotation={[0, -0.892, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_1", associations: { nodes: 19 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_2"
                position={[-0.043, 0.051, -0.015]}
                rotation={[0, -0.745, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_2", associations: { nodes: 20 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_3"
                position={[-0.033, 0.051, -0.009]}
                rotation={[0, -0.468, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_3", associations: { nodes: 21 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_4"
                position={[-0.023, 0.051, -0.005]}
                rotation={[0, -0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_4", associations: { nodes: 22 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_5"
                position={[-0.014, 0.051, -0.003]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_5", associations: { nodes: 23 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_6"
                position={[-0.005, 0.051, -0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_1_6", associations: { nodes: 24 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_7"
                position={[0.005, 0.051, -0.003]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_7", associations: { nodes: 25 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_8"
                position={[0.014, 0.051, -0.005]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_8", associations: { nodes: 26 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_9"
                position={[0.023, 0.051, -0.009]}
                rotation={[0, 0.232, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_9", associations: { nodes: 27 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_10"
                position={[0.033, 0.051, -0.009]}
                rotation={[0, 0.364, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_10", associations: { nodes: 28 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_11"
                position={[0.042, 0.051, -0.017]}
                rotation={[0, 0.844, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_11", associations: { nodes: 29 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_12"
                position={[0.049, 0.049, -0.03]}
                rotation={[0, 1.065, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_1_12", associations: { nodes: 30 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_0"
                position={[-0.06, 0.041, -0.036]}
                rotation={[0, -0.933, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_0", associations: { nodes: 31 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_1"
                position={[-0.051, 0.041, -0.026]}
                rotation={[0, -0.904, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_1", associations: { nodes: 32 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_2"
                position={[-0.043, 0.041, -0.015]}
                rotation={[0, -0.726, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_2", associations: { nodes: 33 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_3"
                position={[-0.033, 0.041, -0.008]}
                rotation={[0, -0.467, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_3", associations: { nodes: 34 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_4"
                position={[-0.023, 0.041, -0.005]}
                rotation={[0, -0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_4", associations: { nodes: 35 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_5"
                position={[-0.014, 0.041, -0.002]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_5", associations: { nodes: 36 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_6"
                position={[-0.005, 0.041, -0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_2_6", associations: { nodes: 37 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_7"
                position={[0.005, 0.041, -0.002]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_7", associations: { nodes: 38 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_8"
                position={[0.014, 0.041, -0.005]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_8", associations: { nodes: 39 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_9"
                position={[0.023, 0.041, -0.008]}
                rotation={[0, 0.274, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_9", associations: { nodes: 40 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_10"
                position={[0.033, 0.041, -0.011]}
                rotation={[0, 0.351, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_10", associations: { nodes: 41 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_11"
                position={[0.042, 0.041, -0.015]}
                rotation={[0, 0.799, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_11", associations: { nodes: 42 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_12"
                position={[0.049, 0.041, -0.028]}
                rotation={[0, 1.086, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_2_12", associations: { nodes: 43 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_0"
                position={[-0.059, 0.031, -0.036]}
                rotation={[0, -1.004, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_0", associations: { nodes: 44 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_1"
                position={[-0.052, 0.031, -0.024]}
                rotation={[0, -0.919, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_1", associations: { nodes: 45 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_2"
                position={[-0.043, 0.031, -0.015]}
                rotation={[0, -0.704, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_2", associations: { nodes: 46 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_3"
                position={[-0.033, 0.031, -0.008]}
                rotation={[0, -0.466, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_3", associations: { nodes: 47 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_4"
                position={[-0.023, 0.031, -0.005]}
                rotation={[0, -0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_4", associations: { nodes: 48 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_5"
                position={[-0.014, 0.031, -0.002]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_5", associations: { nodes: 49 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_6"
                position={[-0.005, 0.031, -0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_3_6", associations: { nodes: 50 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_7"
                position={[0.005, 0.031, -0.002]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_7", associations: { nodes: 51 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_8"
                position={[0.014, 0.031, -0.005]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_8", associations: { nodes: 52 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_9"
                position={[0.023, 0.031, -0.008]}
                rotation={[0, 0.272, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_9", associations: { nodes: 53 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_10"
                position={[0.033, 0.031, -0.01]}
                rotation={[0, 0.336, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_10", associations: { nodes: 54 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_11"
                position={[0.042, 0.031, -0.015]}
                rotation={[0, 0.791, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_11", associations: { nodes: 55 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_12"
                position={[0.048, 0.031, -0.027]}
                rotation={[0, 1.096, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_3_12", associations: { nodes: 56 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_0"
                position={[-0.059, 0.021, -0.036]}
                rotation={[0, -0.98, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_0", associations: { nodes: 57 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_1"
                position={[-0.051, 0.021, -0.025]}
                rotation={[0, -0.927, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_1", associations: { nodes: 58 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_2"
                position={[-0.043, 0.021, -0.015]}
                rotation={[0, -0.724, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_2", associations: { nodes: 59 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_3"
                position={[-0.033, 0.021, -0.008]}
                rotation={[0, -0.467, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_3", associations: { nodes: 60 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_4"
                position={[-0.023, 0.021, -0.004]}
                rotation={[0, -0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_4", associations: { nodes: 61 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_5"
                position={[-0.014, 0.021, -0.002]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_5", associations: { nodes: 62 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_6"
                position={[-0.005, 0.021, 0]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_4_6", associations: { nodes: 63 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_7"
                position={[0.005, 0.021, -0.002]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_7", associations: { nodes: 64 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_8"
                position={[0.014, 0.021, -0.004]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_8", associations: { nodes: 65 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_9"
                position={[0.023, 0.021, -0.008]}
                rotation={[0, 0.256, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_9", associations: { nodes: 66 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_10"
                position={[0.033, 0.021, -0.009]}
                rotation={[0, 0.332, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_10", associations: { nodes: 67 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_11"
                position={[0.042, 0.021, -0.015]}
                rotation={[0, 0.803, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_11", associations: { nodes: 68 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_12"
                position={[0.048, 0.021, -0.026]}
                rotation={[0, 1.096, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_4_12", associations: { nodes: 69 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_0"
                position={[-0.058, 0.011, -0.035]}
                rotation={[0, -0.977, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_0", associations: { nodes: 70 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_1"
                position={[-0.051, 0.011, -0.025]}
                rotation={[0, -0.946, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_1", associations: { nodes: 71 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_2"
                position={[-0.043, 0.011, -0.014]}
                rotation={[0, -0.746, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_2", associations: { nodes: 72 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_3"
                position={[-0.033, 0.011, -0.008]}
                rotation={[0, -0.467, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_3", associations: { nodes: 73 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_4"
                position={[-0.023, 0.011, -0.004]}
                rotation={[0, -0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_4", associations: { nodes: 74 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_5"
                position={[-0.014, 0.011, -0.002]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_5", associations: { nodes: 75 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_6"
                position={[-0.005, 0.011, 0]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_5_6", associations: { nodes: 76 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_7"
                position={[0.005, 0.011, -0.002]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_7", associations: { nodes: 77 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_8"
                position={[0.014, 0.011, -0.004]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_8", associations: { nodes: 78 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_9"
                position={[0.023, 0.011, -0.008]}
                rotation={[0, 0.256, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_9", associations: { nodes: 79 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_10"
                position={[0.033, 0.011, -0.009]}
                rotation={[0, 0.332, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_10", associations: { nodes: 80 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_11"
                position={[0.042, 0.011, -0.014]}
                rotation={[0, 0.803, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_11", associations: { nodes: 81 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_12"
                position={[0.048, 0.011, -0.025]}
                rotation={[0, 1.096, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_5_12", associations: { nodes: 82 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_0"
                position={[-0.057, 0.001, -0.036]}
                rotation={[0, -1.058, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_0", associations: { nodes: 83 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_1"
                position={[-0.051, 0.001, -0.024]}
                rotation={[0, -0.985, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_1", associations: { nodes: 84 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_2"
                position={[-0.043, 0.001, -0.014]}
                rotation={[0, -0.744, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_2", associations: { nodes: 85 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_3"
                position={[-0.033, 0.001, -0.008]}
                rotation={[0, -0.466, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_3", associations: { nodes: 86 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_4"
                position={[-0.023, 0.001, -0.004]}
                rotation={[0, -0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_4", associations: { nodes: 87 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_5"
                position={[-0.014, 0.001, -0.002]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_5", associations: { nodes: 88 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_6"
                position={[-0.005, 0.001, 0]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_6_6", associations: { nodes: 89 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_7"
                position={[0.005, 0.001, -0.002]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_7", associations: { nodes: 90 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_8"
                position={[0.014, 0.001, -0.004]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_8", associations: { nodes: 91 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_9"
                position={[0.023, 0.001, -0.008]}
                rotation={[0, 0.242, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_9", associations: { nodes: 92 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_10"
                position={[0.033, 0.001, -0.009]}
                rotation={[0, 0.329, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_10", associations: { nodes: 93 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_11"
                position={[0.042, 0.001, -0.014]}
                rotation={[0, 0.813, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_11", associations: { nodes: 94 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_12"
                position={[0.047, 0.001, -0.024]}
                rotation={[0, 1.096, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_6_12", associations: { nodes: 95 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_0"
                position={[-0.056, -0.009, -0.036]}
                rotation={[0, -1.121, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_0", associations: { nodes: 96 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_1"
                position={[-0.051, -0.009, -0.024]}
                rotation={[0, -1.03, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_1", associations: { nodes: 97 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_2"
                position={[-0.043, -0.009, -0.014]}
                rotation={[0, -0.757, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_2", associations: { nodes: 98 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_3"
                position={[-0.033, -0.009, -0.007]}
                rotation={[0, -0.468, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_3", associations: { nodes: 99 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_4"
                position={[-0.023, -0.009, -0.004]}
                rotation={[0, -0.311, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_4", associations: { nodes: 100 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_5"
                position={[-0.014, -0.009, -0.001]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_5", associations: { nodes: 101 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_6"
                position={[-0.005, -0.009, 0]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_7_6", associations: { nodes: 102 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_7"
                position={[0.005, -0.009, -0.001]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_7", associations: { nodes: 103 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_8"
                position={[0.014, -0.009, -0.004]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_8", associations: { nodes: 104 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_9"
                position={[0.023, -0.009, -0.007]}
                rotation={[0, 0.224, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_9", associations: { nodes: 105 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_10"
                position={[0.033, -0.009, -0.008]}
                rotation={[0, 0.342, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_10", associations: { nodes: 106 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_11"
                position={[0.042, -0.009, -0.014]}
                rotation={[0, 0.833, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_11", associations: { nodes: 107 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_12"
                position={[0.047, -0.009, -0.023]}
                rotation={[0, 1.071, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_7_12", associations: { nodes: 108 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_0"
                position={[-0.055, -0.019, -0.036]}
                rotation={[0, -1.13, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_0", associations: { nodes: 109 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_1"
                position={[-0.049, -0.019, -0.025]}
                rotation={[0, -1.068, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_1", associations: { nodes: 110 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_2"
                position={[-0.043, -0.019, -0.013]}
                rotation={[0, -0.794, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_2", associations: { nodes: 111 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_3"
                position={[-0.033, -0.019, -0.007]}
                rotation={[0, -0.473, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_3", associations: { nodes: 112 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_4"
                position={[-0.023, -0.019, -0.004]}
                rotation={[0, -0.314, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_4", associations: { nodes: 113 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_5"
                position={[-0.014, -0.019, -0.001]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_5", associations: { nodes: 114 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_6"
                position={[-0.005, -0.019, 0]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_8_6", associations: { nodes: 115 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_7"
                position={[0.005, -0.019, -0.001]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_7", associations: { nodes: 116 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_8"
                position={[0.014, -0.019, -0.004]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_8", associations: { nodes: 117 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_9"
                position={[0.023, -0.019, -0.007]}
                rotation={[0, 0.239, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_9", associations: { nodes: 118 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_10"
                position={[0.033, -0.019, -0.008]}
                rotation={[0, 0.394, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_10", associations: { nodes: 119 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_11"
                position={[0.041, -0.019, -0.015]}
                rotation={[0, 0.842, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_11", associations: { nodes: 120 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_12"
                position={[0.047, -0.019, -0.024]}
                rotation={[0, 1.016, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_8_12", associations: { nodes: 121 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_0"
                position={[-0.054, -0.03, -0.036]}
                rotation={[0, -1.149, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_0", associations: { nodes: 122 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_1"
                position={[-0.048, -0.029, -0.024]}
                rotation={[0, -1.09, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_1", associations: { nodes: 123 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_2"
                position={[-0.042, -0.029, -0.013]}
                rotation={[0, -0.826, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_2", associations: { nodes: 124 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_3"
                position={[-0.033, -0.029, -0.007]}
                rotation={[0, -0.495, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_3", associations: { nodes: 125 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_4"
                position={[-0.023, -0.029, -0.003]}
                rotation={[0, -0.316, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_4", associations: { nodes: 126 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_5"
                position={[-0.014, -0.029, -0.001]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_5", associations: { nodes: 127 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_6"
                position={[-0.005, -0.029, 0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_9_6", associations: { nodes: 128 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_7"
                position={[0.005, -0.029, -0.001]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_7", associations: { nodes: 129 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_8"
                position={[0.014, -0.029, -0.003]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_8", associations: { nodes: 130 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_9"
                position={[0.023, -0.029, -0.007]}
                rotation={[0, 0.289, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_9", associations: { nodes: 131 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_10"
                position={[0.033, -0.029, -0.009]}
                rotation={[0, 0.461, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_10", associations: { nodes: 132 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_11"
                position={[0.041, -0.029, -0.016]}
                rotation={[0, 0.83, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_11", associations: { nodes: 133 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_12"
                position={[0.046, -0.029, -0.023]}
                rotation={[0, 0.958, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_9_12", associations: { nodes: 134 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_0"
                position={[-0.053, -0.04, -0.037]}
                rotation={[0, -1.18, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_0", associations: { nodes: 135 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_1"
                position={[-0.048, -0.039, -0.024]}
                rotation={[0, -1.087, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_1", associations: { nodes: 136 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_2"
                position={[-0.041, -0.039, -0.014]}
                rotation={[0, -0.861, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_2", associations: { nodes: 137 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_3"
                position={[-0.033, -0.039, -0.007]}
                rotation={[0, -0.549, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_3", associations: { nodes: 138 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_4"
                position={[-0.023, -0.039, -0.003]}
                rotation={[0, -0.318, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_4", associations: { nodes: 139 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_5"
                position={[-0.014, -0.039, -0.001]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_5", associations: { nodes: 140 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_6"
                position={[-0.005, -0.039, 0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_10_6", associations: { nodes: 141 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_7"
                position={[0.005, -0.039, -0.001]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_7", associations: { nodes: 142 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_8"
                position={[0.014, -0.039, -0.003]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_8", associations: { nodes: 143 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_9"
                position={[0.023, -0.039, -0.007]}
                rotation={[0, 0.352, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_10_9", associations: { nodes: 144 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_10"
                position={[0.033, -0.039, -0.01]}
                rotation={[0, 0.516, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_10_10",
                  associations: { nodes: 145 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_11"
                position={[0.041, -0.039, -0.017]}
                rotation={[0, 0.798, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_10_11",
                  associations: { nodes: 146 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_12"
                position={[0.046, -0.039, -0.023]}
                rotation={[0, 0.91, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_10_12",
                  associations: { nodes: 147 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_0"
                position={[-0.052, -0.05, -0.037]}
                rotation={[0, -1.176, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_0", associations: { nodes: 148 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_1"
                position={[-0.046, -0.049, -0.024]}
                rotation={[0, -1.072, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_1", associations: { nodes: 149 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_2"
                position={[-0.04, -0.049, -0.016]}
                rotation={[0, -0.913, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_2", associations: { nodes: 150 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_3"
                position={[-0.033, -0.049, -0.007]}
                rotation={[0, -0.628, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_3", associations: { nodes: 151 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_4"
                position={[-0.023, -0.049, -0.003]}
                rotation={[0, -0.33, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_4", associations: { nodes: 152 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_5"
                position={[-0.014, -0.049, 0]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_5", associations: { nodes: 153 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_6"
                position={[-0.005, -0.049, 0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_11_6", associations: { nodes: 154 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_7"
                position={[0.005, -0.049, 0]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_7", associations: { nodes: 155 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_8"
                position={[0.014, -0.049, -0.003]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_8", associations: { nodes: 156 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_9"
                position={[0.023, -0.049, -0.006]}
                rotation={[0, 0.409, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_11_9", associations: { nodes: 157 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_10"
                position={[0.033, -0.049, -0.011]}
                rotation={[0, 0.555, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_11_10",
                  associations: { nodes: 158 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_11"
                position={[0.041, -0.049, -0.017]}
                rotation={[0, 0.764, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_11_11",
                  associations: { nodes: 159 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_12"
                position={[0.045, -0.049, -0.022]}
                rotation={[0, 0.88, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_11_12",
                  associations: { nodes: 160 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_0"
                position={[-0.047, -0.061, -0.029]}
                rotation={[0, -1.166, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_0", associations: { nodes: 161 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_1"
                position={[-0.045, -0.061, -0.025]}
                rotation={[0, -1.064, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_1", associations: { nodes: 162 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_2"
                position={[-0.039, -0.062, -0.017]}
                rotation={[0, -0.941, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_2", associations: { nodes: 163 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_3"
                position={[-0.033, -0.061, -0.007]}
                rotation={[0, -0.669, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_3", associations: { nodes: 164 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_4"
                position={[-0.023, -0.061, -0.003]}
                rotation={[0, -0.342, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_4", associations: { nodes: 165 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_5"
                position={[-0.014, -0.061, 0]}
                rotation={[0, -0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_5", associations: { nodes: 166 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_6"
                position={[-0.005, -0.059, 0.001]}
                rotation={[0, 0, -Math.PI]}
                scale={0.667}
                userData={{ name: "Hose_L_12_6", associations: { nodes: 167 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_7"
                position={[0.005, -0.059, 0]}
                rotation={[0, 0.213, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_7", associations: { nodes: 168 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_8"
                position={[0.014, -0.059, -0.003]}
                rotation={[0, 0.31, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_8", associations: { nodes: 169 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_9"
                position={[0.023, -0.059, -0.006]}
                rotation={[0, 0.431, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_L_12_9", associations: { nodes: 170 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_10"
                position={[0.033, -0.056, -0.012]}
                rotation={[0, 0.569, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_12_10",
                  associations: { nodes: 171 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_11"
                position={[0.041, -0.052, -0.017]}
                rotation={[0, 0.752, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_12_11",
                  associations: { nodes: 172 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_12"
                position={[0.043, -0.051, -0.02]}
                rotation={[0, 0.87, Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_L_12_12",
                  associations: { nodes: 173 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
            </group>
            <group
              name="Cargo_Rechts"
              position={[-0.167, -0.079, 0.075]}
              rotation={[0.175, Math.PI / 2, 0]}
              scale={[1.5, 1.5, 1.5]}
              userData={{ name: "Cargo_Rechts", associations: { nodes: 174 } }}
            >
              <group
                name="Hose_R_0_0"
                position={[-0.018, 0.052, 0.06]}
                rotation={[-Math.PI, -1.532, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_0", associations: { nodes: 175 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_1"
                position={[-0.016, 0.058, 0.053]}
                rotation={[Math.PI, -1.463, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_1", associations: { nodes: 176 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_2"
                position={[-0.014, 0.06, 0.042]}
                rotation={[Math.PI, -1.349, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_2", associations: { nodes: 177 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_3"
                position={[-0.011, 0.061, 0.031]}
                rotation={[Math.PI, -1.153, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_3", associations: { nodes: 178 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_4"
                position={[-0.004, 0.06, 0.021]}
                rotation={[Math.PI, -0.805, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_4", associations: { nodes: 179 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_5"
                position={[0.003, 0.059, 0.016]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_5", associations: { nodes: 180 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_6"
                position={[0.011, 0.058, 0.011]}
                rotation={[Math.PI, -0.286, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_6", associations: { nodes: 181 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_7"
                position={[0.021, 0.057, 0.01]}
                rotation={[Math.PI, -0.047, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_7", associations: { nodes: 182 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_8"
                position={[0.03, 0.055, 0.01]}
                rotation={[Math.PI, 0.08, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_8", associations: { nodes: 183 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_9"
                position={[0.04, 0.053, 0.011]}
                rotation={[Math.PI, 0.276, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_9", associations: { nodes: 184 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_10"
                position={[0.048, 0.051, 0.014]}
                rotation={[Math.PI, 0.413, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_10", associations: { nodes: 185 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_11"
                position={[0.06, 0.049, 0.019]}
                rotation={[Math.PI, 0.487, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_11", associations: { nodes: 186 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_12"
                position={[0.061, 0.048, 0.021]}
                rotation={[Math.PI, 0.516, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_0_12", associations: { nodes: 187 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_0"
                position={[-0.023, 0.042, 0.066]}
                rotation={[Math.PI, -1.51, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_0", associations: { nodes: 188 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_1"
                position={[-0.019, 0.049, 0.054]}
                rotation={[Math.PI, -1.46, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_1", associations: { nodes: 189 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_2"
                position={[-0.016, 0.052, 0.042]}
                rotation={[Math.PI, -1.36, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_2", associations: { nodes: 190 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_3"
                position={[-0.013, 0.053, 0.03]}
                rotation={[Math.PI, -1.147, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_3", associations: { nodes: 191 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_4"
                position={[-0.006, 0.052, 0.02]}
                rotation={[Math.PI, -0.796, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_4", associations: { nodes: 192 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_5"
                position={[0.001, 0.051, 0.014]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_5", associations: { nodes: 193 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_6"
                position={[0.01, 0.05, 0.009]}
                rotation={[Math.PI, -0.298, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_6", associations: { nodes: 194 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_7"
                position={[0.019, 0.048, 0.008]}
                rotation={[Math.PI, -0.062, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_7", associations: { nodes: 195 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_8"
                position={[0.028, 0.046, 0.007]}
                rotation={[Math.PI, 0.078, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_8", associations: { nodes: 196 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_9"
                position={[0.038, 0.044, 0.009]}
                rotation={[Math.PI, 0.265, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_9", associations: { nodes: 197 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_10"
                position={[0.046, 0.043, 0.012]}
                rotation={[Math.PI, 0.4, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_10", associations: { nodes: 198 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_11"
                position={[0.058, 0.04, 0.017]}
                rotation={[Math.PI, 0.496, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_11", associations: { nodes: 199 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_12"
                position={[0.07, 0.034, 0.023]}
                rotation={[Math.PI, 0.535, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_1_12", associations: { nodes: 200 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_0"
                position={[-0.024, 0.035, 0.064]}
                rotation={[-Math.PI, -1.529, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_0", associations: { nodes: 201 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_1"
                position={[-0.022, 0.041, 0.053]}
                rotation={[-Math.PI, -1.478, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_1", associations: { nodes: 202 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_2"
                position={[-0.02, 0.042, 0.04]}
                rotation={[Math.PI, -1.355, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_2", associations: { nodes: 203 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_3"
                position={[-0.016, 0.043, 0.028]}
                rotation={[-Math.PI, -1.119, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_3", associations: { nodes: 204 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_4"
                position={[-0.009, 0.042, 0.018]}
                rotation={[Math.PI, -0.78, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_4", associations: { nodes: 205 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_5"
                position={[-0.001, 0.042, 0.013]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_5", associations: { nodes: 206 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_6"
                position={[0.007, 0.041, 0.008]}
                rotation={[Math.PI, -0.324, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_6", associations: { nodes: 207 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_7"
                position={[0.016, 0.039, 0.006]}
                rotation={[Math.PI, -0.087, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_7", associations: { nodes: 208 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_8"
                position={[0.026, 0.037, 0.005]}
                rotation={[Math.PI, 0.074, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_8", associations: { nodes: 209 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_9"
                position={[0.035, 0.035, 0.007]}
                rotation={[Math.PI, 0.235, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_9", associations: { nodes: 210 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_10"
                position={[0.045, 0.033, 0.009]}
                rotation={[Math.PI, 0.355, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_10", associations: { nodes: 211 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_11"
                position={[0.055, 0.03, 0.013]}
                rotation={[Math.PI, 0.486, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_11", associations: { nodes: 212 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_12"
                position={[0.068, 0.026, 0.02]}
                rotation={[Math.PI, 0.556, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_2_12", associations: { nodes: 213 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_0"
                position={[-0.025, 0.026, 0.063]}
                rotation={[0, -1.546, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_0", associations: { nodes: 214 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_1"
                position={[-0.025, 0.031, 0.052]}
                rotation={[Math.PI, -1.509, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_1", associations: { nodes: 215 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_2"
                position={[-0.022, 0.033, 0.039]}
                rotation={[Math.PI, -1.339, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_2", associations: { nodes: 216 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_3"
                position={[-0.018, 0.033, 0.026]}
                rotation={[Math.PI, -1.086, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_3", associations: { nodes: 217 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_4"
                position={[-0.011, 0.033, 0.017]}
                rotation={[Math.PI, -0.762, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_4", associations: { nodes: 218 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_5"
                position={[-0.003, 0.032, 0.011]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_5", associations: { nodes: 219 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_6"
                position={[0.005, 0.031, 0.006]}
                rotation={[Math.PI, -0.339, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_6", associations: { nodes: 220 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_7"
                position={[0.014, 0.029, 0.004]}
                rotation={[Math.PI, -0.099, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_7", associations: { nodes: 221 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_8"
                position={[0.024, 0.027, 0.004]}
                rotation={[Math.PI, 0.053, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_8", associations: { nodes: 222 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_9"
                position={[0.033, 0.025, 0.004]}
                rotation={[Math.PI, 0.197, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_9", associations: { nodes: 223 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_10"
                position={[0.042, 0.023, 0.006]}
                rotation={[Math.PI, 0.335, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_10", associations: { nodes: 224 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_11"
                position={[0.053, 0.021, 0.01]}
                rotation={[Math.PI, 0.48, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_11", associations: { nodes: 225 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_12"
                position={[0.065, 0.017, 0.018]}
                rotation={[Math.PI, 0.551, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_3_12", associations: { nodes: 226 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_0"
                position={[-0.028, 0.017, 0.06]}
                rotation={[0, -1.553, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_0", associations: { nodes: 227 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_1"
                position={[-0.027, 0.021, 0.049]}
                rotation={[Math.PI, -1.516, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_1", associations: { nodes: 228 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_2"
                position={[-0.025, 0.023, 0.036]}
                rotation={[Math.PI, -1.352, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_2", associations: { nodes: 229 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_3"
                position={[-0.021, 0.024, 0.024]}
                rotation={[Math.PI, -1.067, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_3", associations: { nodes: 230 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_4"
                position={[-0.013, 0.023, 0.015]}
                rotation={[Math.PI, -0.741, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_4", associations: { nodes: 231 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_5"
                position={[-0.005, 0.022, 0.009]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_5", associations: { nodes: 232 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_6"
                position={[0.003, 0.021, 0.004]}
                rotation={[Math.PI, -0.339, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_6", associations: { nodes: 233 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_7"
                position={[0.012, 0.02, 0.002]}
                rotation={[Math.PI, -0.11, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_7", associations: { nodes: 234 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_8"
                position={[0.021, 0.018, 0.001]}
                rotation={[Math.PI, 0.013, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_8", associations: { nodes: 235 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_9"
                position={[0.031, 0.015, 0.002]}
                rotation={[Math.PI, 0.176, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_9", associations: { nodes: 236 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_10"
                position={[0.04, 0.014, 0.004]}
                rotation={[Math.PI, 0.362, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_10", associations: { nodes: 237 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_11"
                position={[0.05, 0.011, 0.009]}
                rotation={[Math.PI, 0.488, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_11", associations: { nodes: 238 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_12"
                position={[0.063, 0.007, 0.016]}
                rotation={[Math.PI, 0.532, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_4_12", associations: { nodes: 239 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_0"
                position={[-0.029, 0.007, 0.058]}
                rotation={[Math.PI, -1.57, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_0", associations: { nodes: 240 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_1"
                position={[-0.028, 0.011, 0.047]}
                rotation={[-Math.PI, -1.526, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_1", associations: { nodes: 241 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_2"
                position={[-0.027, 0.014, 0.034]}
                rotation={[Math.PI, -1.365, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_2", associations: { nodes: 242 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_3"
                position={[-0.023, 0.014, 0.022]}
                rotation={[Math.PI, -1.057, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_3", associations: { nodes: 243 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_4"
                position={[-0.015, 0.014, 0.013]}
                rotation={[Math.PI, -0.738, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_4", associations: { nodes: 244 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_5"
                position={[-0.008, 0.013, 0.007]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_5", associations: { nodes: 245 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_6"
                position={[0.001, 0.012, 0.003]}
                rotation={[Math.PI, -0.339, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_6", associations: { nodes: 246 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_7"
                position={[0.01, 0.01, 0.001]}
                rotation={[Math.PI, -0.121, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_7", associations: { nodes: 247 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_8"
                position={[0.019, 0.008, 0]}
                rotation={[Math.PI, -0.018, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_8", associations: { nodes: 248 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_9"
                position={[0.029, 0.006, -0.001]}
                rotation={[Math.PI, 0.186, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_9", associations: { nodes: 249 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_10"
                position={[0.038, 0.004, 0.003]}
                rotation={[Math.PI, 0.38, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_10", associations: { nodes: 250 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_11"
                position={[0.048, 0.002, 0.007]}
                rotation={[Math.PI, 0.477, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_11", associations: { nodes: 251 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_12"
                position={[0.06, -0.002, 0.014]}
                rotation={[Math.PI, 0.53, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_5_12", associations: { nodes: 252 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_0"
                position={[-0.032, -0.003, 0.056]}
                rotation={[0, -1.54, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_0", associations: { nodes: 253 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_1"
                position={[-0.031, 0.001, 0.045]}
                rotation={[Math.PI, -1.547, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_1", associations: { nodes: 254 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_2"
                position={[-0.029, 0.004, 0.032]}
                rotation={[-Math.PI, -1.348, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_2", associations: { nodes: 255 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_3"
                position={[-0.025, 0.005, 0.021]}
                rotation={[Math.PI, -1.044, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_3", associations: { nodes: 256 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_4"
                position={[-0.018, 0.004, 0.011]}
                rotation={[Math.PI, -0.747, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_4", associations: { nodes: 257 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_5"
                position={[-0.01, 0.003, 0.006]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_5", associations: { nodes: 258 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_6"
                position={[-0.002, 0.002, 0.001]}
                rotation={[Math.PI, -0.339, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_6", associations: { nodes: 259 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_7"
                position={[0.007, 0, -0.001]}
                rotation={[Math.PI, -0.124, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_7", associations: { nodes: 260 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_8"
                position={[0.017, -0.002, -0.002]}
                rotation={[Math.PI, -0.02, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_8", associations: { nodes: 261 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_9"
                position={[0.026, -0.004, -0.002]}
                rotation={[Math.PI, 0.196, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_9", associations: { nodes: 262 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_10"
                position={[0.036, -0.005, 0.001]}
                rotation={[Math.PI, 0.379, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_10", associations: { nodes: 263 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_11"
                position={[0.046, -0.008, 0.005]}
                rotation={[Math.PI, 0.465, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_11", associations: { nodes: 264 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_12"
                position={[0.057, -0.011, 0.011]}
                rotation={[Math.PI, 0.529, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_6_12", associations: { nodes: 265 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_0"
                position={[-0.036, -0.011, 0.053]}
                rotation={[0, -1.565, -Math.PI]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_0", associations: { nodes: 266 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_1"
                position={[-0.034, -0.007, 0.041]}
                rotation={[Math.PI, -1.516, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_1", associations: { nodes: 267 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_2"
                position={[-0.033, -0.005, 0.03]}
                rotation={[Math.PI, -1.311, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_2", associations: { nodes: 268 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_3"
                position={[-0.027, -0.005, 0.019]}
                rotation={[Math.PI, -1.023, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_3", associations: { nodes: 269 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_4"
                position={[-0.02, -0.005, 0.01]}
                rotation={[Math.PI, -0.745, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_4", associations: { nodes: 270 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_5"
                position={[-0.012, -0.006, 0.004]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_5", associations: { nodes: 271 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_6"
                position={[-0.004, -0.007, -0.001]}
                rotation={[Math.PI, -0.339, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_6", associations: { nodes: 272 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_7"
                position={[0.005, -0.009, -0.003]}
                rotation={[Math.PI, -0.124, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_7", associations: { nodes: 273 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_8"
                position={[0.015, -0.011, -0.004]}
                rotation={[Math.PI, 0.003, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_8", associations: { nodes: 274 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_9"
                position={[0.024, -0.013, -0.003]}
                rotation={[Math.PI, 0.207, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_9", associations: { nodes: 275 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_10"
                position={[0.034, -0.015, 0]}
                rotation={[Math.PI, 0.374, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_10", associations: { nodes: 276 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_11"
                position={[0.044, -0.018, 0.004]}
                rotation={[Math.PI, 0.48, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_11", associations: { nodes: 277 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_12"
                position={[0.054, -0.021, 0.011]}
                rotation={[Math.PI, 0.546, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_7_12", associations: { nodes: 278 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_0"
                position={[-0.039, -0.02, 0.051]}
                rotation={[Math.PI, -1.518, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_0", associations: { nodes: 279 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_1"
                position={[-0.037, -0.017, 0.039]}
                rotation={[Math.PI, -1.461, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_1", associations: { nodes: 280 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_2"
                position={[-0.035, -0.015, 0.028]}
                rotation={[Math.PI, -1.283, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_2", associations: { nodes: 281 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_3"
                position={[-0.03, -0.014, 0.017]}
                rotation={[Math.PI, -1.01, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_3", associations: { nodes: 282 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_4"
                position={[-0.022, -0.015, 0.008]}
                rotation={[Math.PI, -0.733, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_4", associations: { nodes: 283 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_5"
                position={[-0.014, -0.016, 0.002]}
                rotation={[Math.PI, -0.557, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_5", associations: { nodes: 284 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_6"
                position={[-0.006, -0.017, -0.002]}
                rotation={[Math.PI, -0.339, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_6", associations: { nodes: 285 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_7"
                position={[0.003, -0.019, -0.004]}
                rotation={[Math.PI, -0.124, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_7", associations: { nodes: 286 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_8"
                position={[0.012, -0.021, -0.006]}
                rotation={[Math.PI, 0.026, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_8", associations: { nodes: 287 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_9"
                position={[0.022, -0.023, -0.005]}
                rotation={[Math.PI, 0.231, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_9", associations: { nodes: 288 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_10"
                position={[0.031, -0.025, -0.001]}
                rotation={[Math.PI, 0.382, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_10", associations: { nodes: 289 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_11"
                position={[0.041, -0.027, 0.003]}
                rotation={[Math.PI, 0.508, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_11", associations: { nodes: 290 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_12"
                position={[0.051, -0.03, 0.01]}
                rotation={[Math.PI, 0.589, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_8_12", associations: { nodes: 291 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_0"
                position={[-0.041, -0.028, 0.049]}
                rotation={[Math.PI, -1.521, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_0", associations: { nodes: 292 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_1"
                position={[-0.04, -0.026, 0.036]}
                rotation={[Math.PI, -1.443, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_1", associations: { nodes: 293 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_2"
                position={[-0.037, -0.025, 0.026]}
                rotation={[Math.PI, -1.257, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_2", associations: { nodes: 294 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_3"
                position={[-0.032, -0.024, 0.015]}
                rotation={[Math.PI, -0.996, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_3", associations: { nodes: 295 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_4"
                position={[-0.024, -0.025, 0.006]}
                rotation={[Math.PI, -0.726, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_4", associations: { nodes: 296 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_5"
                position={[-0.017, -0.025, 0.001]}
                rotation={[Math.PI, -0.556, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_5", associations: { nodes: 297 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_6"
                position={[-0.008, -0.027, -0.004]}
                rotation={[Math.PI, -0.338, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_6", associations: { nodes: 298 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_7"
                position={[0.001, -0.028, -0.006]}
                rotation={[Math.PI, -0.124, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_7", associations: { nodes: 299 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_8"
                position={[0.01, -0.03, -0.007]}
                rotation={[Math.PI, 0.038, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_8", associations: { nodes: 300 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_9"
                position={[0.02, -0.033, -0.006]}
                rotation={[Math.PI, 0.249, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_9", associations: { nodes: 301 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_10"
                position={[0.029, -0.034, -0.003]}
                rotation={[Math.PI, 0.404, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_10", associations: { nodes: 302 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_11"
                position={[0.039, -0.037, 0.002]}
                rotation={[Math.PI, 0.519, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_11", associations: { nodes: 303 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_12"
                position={[0.048, -0.04, 0.008]}
                rotation={[Math.PI, 0.579, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_9_12", associations: { nodes: 304 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_0"
                position={[-0.043, -0.038, 0.047]}
                rotation={[Math.PI, -1.543, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_0", associations: { nodes: 305 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_1"
                position={[-0.042, -0.036, 0.034]}
                rotation={[Math.PI, -1.445, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_1", associations: { nodes: 306 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_2"
                position={[-0.04, -0.034, 0.024]}
                rotation={[Math.PI, -1.232, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_2", associations: { nodes: 307 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_3"
                position={[-0.034, -0.034, 0.013]}
                rotation={[Math.PI, -0.983, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_3", associations: { nodes: 308 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_4"
                position={[-0.027, -0.034, 0.005]}
                rotation={[Math.PI, -0.735, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_4", associations: { nodes: 309 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_5"
                position={[-0.019, -0.035, -0.001]}
                rotation={[Math.PI, -0.547, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_5", associations: { nodes: 310 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_6"
                position={[-0.011, -0.036, -0.006]}
                rotation={[Math.PI, -0.333, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_6", associations: { nodes: 311 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_7"
                position={[-0.002, -0.038, -0.008]}
                rotation={[Math.PI, -0.133, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_7", associations: { nodes: 312 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_8"
                position={[0.008, -0.04, -0.009]}
                rotation={[Math.PI, 0.035, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_8", associations: { nodes: 313 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_9"
                position={[0.017, -0.042, -0.008]}
                rotation={[Math.PI, 0.271, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_10_9", associations: { nodes: 314 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_10"
                position={[0.027, -0.044, -0.004]}
                rotation={[Math.PI, 0.448, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_10_10",
                  associations: { nodes: 315 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_11"
                position={[0.037, -0.047, 0.002]}
                rotation={[Math.PI, 0.497, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_10_11",
                  associations: { nodes: 316 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_12"
                position={[0.046, -0.049, 0.007]}
                rotation={[Math.PI, 0.495, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_10_12",
                  associations: { nodes: 317 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_0"
                position={[-0.044, -0.048, 0.045]}
                rotation={[Math.PI, -1.536, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_0", associations: { nodes: 318 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_1"
                position={[-0.043, -0.046, 0.032]}
                rotation={[Math.PI, -1.438, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_1", associations: { nodes: 319 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_2"
                position={[-0.041, -0.044, 0.022]}
                rotation={[Math.PI, -1.233, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_2", associations: { nodes: 320 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_3"
                position={[-0.036, -0.043, 0.012]}
                rotation={[Math.PI, -0.988, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_3", associations: { nodes: 321 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_4"
                position={[-0.029, -0.044, 0.003]}
                rotation={[Math.PI, -0.746, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_4", associations: { nodes: 322 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_5"
                position={[-0.021, -0.045, -0.003]}
                rotation={[Math.PI, -0.545, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_5", associations: { nodes: 323 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_6"
                position={[-0.012, -0.046, -0.007]}
                rotation={[Math.PI, -0.325, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_6", associations: { nodes: 324 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_7"
                position={[-0.004, -0.047, -0.009]}
                rotation={[Math.PI, -0.132, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_7", associations: { nodes: 325 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_8"
                position={[0.006, -0.049, -0.01]}
                rotation={[Math.PI, 0.028, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_8", associations: { nodes: 326 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_9"
                position={[0.015, -0.052, -0.009]}
                rotation={[Math.PI, 0.297, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_11_9", associations: { nodes: 327 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_10"
                position={[0.025, -0.054, -0.003]}
                rotation={[Math.PI, 0.485, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_11_10",
                  associations: { nodes: 328 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_11"
                position={[0.037, -0.057, 0.004]}
                rotation={[Math.PI, 0.447, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_11_11",
                  associations: { nodes: 329 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_12"
                position={[0.043, -0.058, 0.006]}
                rotation={[-Math.PI, 0.401, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_11_12",
                  associations: { nodes: 330 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_0"
                position={[-0.045, -0.058, 0.033]}
                rotation={[Math.PI, -1.526, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_0", associations: { nodes: 331 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_1"
                position={[-0.044, -0.057, 0.03]}
                rotation={[Math.PI, -1.43, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_1", associations: { nodes: 332 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_2"
                position={[-0.042, -0.057, 0.019]}
                rotation={[Math.PI, -1.243, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_2", associations: { nodes: 333 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_3"
                position={[-0.038, -0.056, 0.01]}
                rotation={[Math.PI, -0.995, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_3", associations: { nodes: 334 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_4"
                position={[-0.03, -0.056, 0.003]}
                rotation={[Math.PI, -0.746, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_4", associations: { nodes: 335 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_5"
                position={[-0.023, -0.057, -0.003]}
                rotation={[Math.PI, -0.551, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_5", associations: { nodes: 336 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_6"
                position={[-0.014, -0.058, -0.008]}
                rotation={[Math.PI, -0.321, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_6", associations: { nodes: 337 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_7"
                position={[-0.005, -0.06, -0.01]}
                rotation={[Math.PI, -0.122, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_7", associations: { nodes: 338 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_8"
                position={[0.003, -0.062, -0.01]}
                rotation={[Math.PI, 0.029, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_8", associations: { nodes: 339 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_9"
                position={[0.013, -0.064, -0.008]}
                rotation={[Math.PI, 0.308, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{ name: "Hose_R_12_9", associations: { nodes: 340 } }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_10"
                position={[0.024, -0.064, -0.002]}
                rotation={[Math.PI, 0.492, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_12_10",
                  associations: { nodes: 341 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_11"
                position={[0.036, -0.061, 0.003]}
                rotation={[Math.PI, 0.42, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_12_11",
                  associations: { nodes: 342 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_12"
                position={[0.042, -0.06, 0.006]}
                rotation={[Math.PI, 0.367, 0]}
                scale={[0.667, 0.667, 0.667]}
                userData={{
                  name: "Hose_R_12_12",
                  associations: { nodes: 343 },
                }}>
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
            </group>
            <mesh
              name="Cargo1"
              geometry={nodes.Cargo1.geometry}
              material={nodes.Cargo1.material}
              userData={{
                name: "Cargo.1",
                associations: { meshes: 1, primitives: 0, nodes: 1 },
                variantData: {},
              }}
            />
            <mesh
              name="Boden"
              geometry={nodes.Boden.geometry}
              material={materials.Boden}
              position={[0, -0.394, -0.069]}
              rotation={[0.175, Math.PI / 2, 0]}
              scale={[0.015, 0.015, 0.015]}
              userData={{
                name: "Boden",
                associations: { meshes: 2, primitives: 0, nodes: 2 },
                variantData: {},
              }}
            />
            <mesh
              name="Cargo_Schatten"
              geometry={nodes.Cargo_Schatten.geometry}
              material={materials["Mat.1"]}
              position={[0, -0.386, -0.068]}
              rotation={[0.175, Math.PI / 2, 0]}
              scale={[1.5, 1.5, 1.5]}
              userData={{
                name: "Cargo_Schatten",
                associations: { meshes: 3, primitives: 0, nodes: 3 },
                variantData: {},
              }}
            />
          </mesh>
        </group>
      </group>
      {props.children}
    </group>
  );
}

useGLTF.preload("./models/Hose.glb");

useGLTF.preload("./models/MP_F.glb");
useGLTF.preload("./models/MP_O.glb");
useGLTF.preload("./models/MP_Z.glb");
useGLTF.preload("./models/MP_F_V1.glb");
useGLTF.preload("./models/MP_O_V1.glb");
useGLTF.preload("./models/MP_Z_V1.glb");
useGLTF.preload("./models/P_1.glb");
useGLTF.preload("./models/P_2.glb");
useGLTF.preload("./models/P_3.glb");
useGLTF.preload("./models/S_1.glb");
useGLTF.preload("./models/S_2.glb");
useGLTF.preload("./models/S_3.glb");
useGLTF.preload("./models/NO.glb");
useGLTF.preload("./models/Y.glb");
useGLTF.preload("./models/C.glb");
useGLTF.preload("./models/Lappen_Links.glb");
useGLTF.preload("./models/Lappen_Rechts.glb");