import { ModuleCategoryData, ModuleData, ModuleLibraryData, ModuleState, Region } from "../store/interfaces";
import { SKETCH_ELEMENT_HEIGHT, SKETCH_ELEMENT_WIDTH } from "./constants";

interface RegionMatrix {
    values: boolean[];
    minAllowedX: number;
    minAllowedY: number;
    maxAllowedX: number;
    maxAllowedY: number;
}

interface ModuleAndCategory {
    module: ModuleData;
    category: ModuleCategoryData;
}

export function getDependantModuleIndices(library: ModuleLibraryData, modules: ModuleState[], parentModuleIndex: number): number[] {
    const result: number[] = [];
    
    modules.forEach((module, moduleIndex) => {
        if(parentModuleIndex !== moduleIndex) {
            var moduleData = getModule(library, module.article);

            // Solve parent constraint.
            // Note(daniel): Currently only works with a single possible parent!
            if(moduleData?.parentConstraint) {
                for (let ex = 0; ex < modules.length; ex++) {
                    const existingModule = modules[ex];
                    if(existingModule !== module) {
                        if(existingModule.article === moduleData?.parentConstraint.article) {
                            if(moduleData.parentConstraint.type === 'bottom-full-width') {
                                result.push(moduleIndex);
                                break;
                            }
                        }
                    }
                }
            }
        }
    });

    return result;
}

export function solveConstraints(library: ModuleLibraryData, modules: ModuleState[], side: string) : ModuleState[] {
    var solvedModules: ModuleState[] = [];

    modules.forEach((module) => {
        var solvedModule = Object.assign({}, module);
        var moduleData = getModule(library, module.article);

        // Solve size constraints.
        if(moduleData !== null) {
            if(moduleData.minWidth !== undefined) {
                if(solvedModule.width < moduleData.minWidth) {
                    solvedModule.width = moduleData.minWidth;
                }
            } else {
                if(solvedModule.width < 1) {
                    solvedModule.width = 1;
                }
            }
            if(moduleData.minHeight !== undefined) {
                if(solvedModule.height < moduleData.minHeight) {
                    solvedModule.height = moduleData.minHeight;
                }
            } else {
                if(solvedModule.height < 1) {
                    solvedModule.height = 1;
                }
            }
            if(moduleData.maxWidth !== undefined) {
                if(solvedModule.width > moduleData.maxWidth) {
                    solvedModule.width = moduleData.maxWidth;
                }
            }
            if(moduleData.maxHeight !== undefined) {
                if(solvedModule.height > moduleData.maxHeight) {
                    solvedModule.height = moduleData.maxHeight;
                }
            }
        }

        // Solve parent constraint.
        // Note(daniel): Currently only works with a single possible parent!
        if(moduleData?.parentConstraint) {
            for (let ex = 0; ex < modules.length; ex++) {
                const existingModule = modules[ex];
                if(existingModule !== module) {
                    if(existingModule.article === moduleData?.parentConstraint.article) {
                        if(moduleData.parentConstraint.type === 'bottom-full-width') {
                            solvedModule.left = existingModule.left;
                            solvedModule.width = existingModule.width;
                            solvedModule.height = Math.min(solvedModule.height, existingModule.height);
                            solvedModule.top = existingModule.top + existingModule.height - solvedModule.height;
                            break;
                        }
                    }
                }
            }
        }

        // Solve attachment constraint.
        if(moduleData?.attachmentConstraint) {
            if(moduleData.attachmentConstraint.attachTop) {
                solvedModule.top = 0;
            } else if(moduleData.attachmentConstraint.attachBottom) {
                solvedModule.top = SKETCH_ELEMENT_HEIGHT - solvedModule.height;
            }
            if(side === 'left') {
                if(moduleData.attachmentConstraint.attachLeft) {
                    solvedModule.left = 0;
                } else if(moduleData.attachmentConstraint.attachRight) {
                    solvedModule.left = SKETCH_ELEMENT_WIDTH - solvedModule.width;
                }
            } else {
                if(moduleData.attachmentConstraint.attachLeft) {
                    solvedModule.left = SKETCH_ELEMENT_WIDTH - solvedModule.width;
                } else if(moduleData.attachmentConstraint.attachRight) {
                    solvedModule.left = 0;
                }
            }
        }

        // Check all other constraints.
        var allowedRegionMatrix = getAllowedRegionMatrix(library, modules, module, side);
        solvedModule.allowed = isAllowedInRegionEx(solvedModule.left, solvedModule.top, solvedModule.width, solvedModule.height, allowedRegionMatrix);

        solvedModules.push(solvedModule);
    });

    return solvedModules;
}

export function canFit(library: ModuleLibraryData, existingModules: ModuleState[], article: string, side: string): boolean {
    var moduleData = getModule(library, article);

    // Solve size constraints.
    if(moduleData !== null) {
        var module: ModuleState = {
            article,
            height: moduleData.minHeight,
            width: moduleData.minWidth,
            left: 0,
            top: 0,
            allowed: false
        };

        var allowedRegionMatrix = getAllowedRegionMatrix(library, existingModules, module, side);
    
        // Fit while trying to keep size the same.
        var fit = false;
        for (let y = allowedRegionMatrix.minAllowedY; y < allowedRegionMatrix.maxAllowedY; y++) {
            for (let x = allowedRegionMatrix.minAllowedX; x < allowedRegionMatrix.maxAllowedX; x++) {
                if(isAllowedInRegionEx(x, y, module.width, module.height, allowedRegionMatrix)) {
                    fit = true;
                    break;
                }
            }
            if(fit) {
                break;
            }
        }
        
        return fit;
    } else {
        return false;
    }
}

function isAllowedInRegion(region: Region, allowedRegionMatrix: RegionMatrix) {
    for (let y = region.minY; y < region.maxY; y++) {
        for (let x = region.minX; x < region.maxX; x++) {
            var allowed = allowedRegionMatrix.values[y * SKETCH_ELEMENT_WIDTH + x];
            if(!allowed) {
                return false;
            }
        }
    }
    return true;
}

function isAllowedInRegionEx(left: number, top: number, width: number, height: number, allowedRegionMatrix: RegionMatrix) {
    return isAllowedInRegion({minX: left, minY: top, maxX: left + width, maxY: top + height}, allowedRegionMatrix);
}

function isInRegion(x: number, y: number, region: Region) {
    if(x < region.maxX && y < region.maxY && x >= region.minX && y >= region.minY) {
        return true;
    }
    return false;
}

export function getAllowedRegionMatrix(library: ModuleLibraryData, existingModules: ModuleState[], module: ModuleState, side: string) : RegionMatrix {
    var allowedRegions: RegionMatrix =  {
        values: [],
        minAllowedX: 22,
        minAllowedY: 22,
        maxAllowedX: 0,
        maxAllowedY: 0
    };
    const isMirrored = (side === 'right');
    var activeModuleData = getModule(library, module.article);

    // Add constraints.
    for (let y = 0; y < SKETCH_ELEMENT_HEIGHT; y++) {
        for (let x = 0; x < SKETCH_ELEMENT_WIDTH; x++) {
            let mirrorX = !isMirrored ? (SKETCH_ELEMENT_WIDTH - 1 - x) : x;
            let allowed = false;

            var regionConstraintsCount = activeModuleData?.regionConstraints?.length;
            if(regionConstraintsCount !== undefined && regionConstraintsCount > 0) {
                activeModuleData?.regionConstraints?.forEach((region) => {
                    if(region.allow) {
                        if(isInRegion(mirrorX, y, region.region)) {
                            allowed = true;
                        }
                    }
                });

                activeModuleData?.regionConstraints?.forEach((region) => {
                    if(!region.allow) {
                        if(isInRegion(mirrorX, y, region.region)) {
                            allowed = false;
                        }
                    }
                });
            } else {
                allowed = true;
            }

            allowedRegions.values.push(allowed);
        }
    }

    // Add existing modules.
    for (let ex = 0; ex < existingModules.length; ex++) {
        const existingModule = existingModules[ex];
        if(existingModule !== module) {
            var existingModuleData = getModule(library, existingModule.article);
            if(existingModuleData?.parentConstraint) {
                if(module.article === existingModuleData?.parentConstraint.article) {
                    continue;
                }
            }

            for (let y = existingModule.top; y < existingModule.top + existingModule.height; y++) {
                for (let x = existingModule.left; x < existingModule.left + existingModule.width; x++) {
                    allowedRegions.values[ y * SKETCH_ELEMENT_WIDTH + x] = false;
                }
            }
        }
    }

    // Add possible parents.
    if(activeModuleData?.parentConstraint) {
        for (let ex = 0; ex < existingModules.length; ex++) {
            const existingModule = existingModules[ex];
            if(existingModule !== module) {
                if(existingModule.article === activeModuleData?.parentConstraint.article) {
                    for (let y = existingModule.top; y < existingModule.top + existingModule.height; y++) {
                        for (let x = existingModule.left; x < existingModule.left + existingModule.width; x++) {
                            allowedRegions.values[ y * SKETCH_ELEMENT_WIDTH + x] = true;
                        }
                    }
                }
            }
        }
    }

    // Add same as self
    if(activeModuleData?.parentConstraint) {
        for (let ex = 0; ex < existingModules.length; ex++) {
            const existingModule = existingModules[ex];
            if(existingModule !== module) {
                if(existingModule.article === module.article) {
                    for (let y = existingModule.top; y < existingModule.top + existingModule.height; y++) {
                        for (let x = existingModule.left; x < existingModule.left + existingModule.width; x++) {
                            allowedRegions.values[ y * SKETCH_ELEMENT_WIDTH + x] = false;
                        }
                    }
                }
            }
        }
    }
    
    // Update allowed region min/max.
    let i = 0;
    for (let y = 0; y < SKETCH_ELEMENT_HEIGHT; y++) {
        for (let x = 0; x < SKETCH_ELEMENT_WIDTH; x++) {
            let allowed = allowedRegions.values[i];

            if(allowed) {
                allowedRegions.minAllowedX = Math.min(allowedRegions.minAllowedX, x);
                allowedRegions.minAllowedY = Math.min(allowedRegions.minAllowedY, y);
                allowedRegions.maxAllowedX = Math.max(allowedRegions.maxAllowedX, x);
                allowedRegions.maxAllowedY = Math.max(allowedRegions.maxAllowedY, y);
            }
            ++i;
        }
    }

    return allowedRegions;
}

export function getAllowedRegions(library: ModuleLibraryData, existingModules: ModuleState[], module: ModuleState, side: string) : Region[] {
    var allowedRegionMatrix = getAllowedRegionMatrix(library, existingModules, module, side);
    var allowedRegions: Region[] = [];

    for (let y = 0; y < SKETCH_ELEMENT_HEIGHT; y++) {
        for (let x = 0; x < SKETCH_ELEMENT_WIDTH; x++) {
            var allowed = allowedRegionMatrix.values[y * SKETCH_ELEMENT_WIDTH + x];
            if(allowed) {
                allowedRegions.push({
                    minX: x,
                    minY: y,
                    maxX: x + 1,
                    maxY: y + 1
                });
            }
        }
    }

    return allowedRegions;
}


export function getModule(library: ModuleLibraryData, article: string) : ModuleData | null {
    var foundModule = null;
    library.categories.forEach(category => {
        category.modules.forEach(module => {
            if(module.article === article) {
                foundModule = module;
            }
        })
    });
    return foundModule;
}

export function getModuleAndCategory(library: ModuleLibraryData, article: string) : ModuleAndCategory | null {
    var found : ModuleAndCategory | null = null;
    library.categories.forEach(category => {
        category.modules.forEach(module => {
            if(module.article === article) {
                found = {
                    module,
                    category
                };
            }
        })
    });
    return found;
}
