import { ModuleLibraryData, PocketState, PricingData } from "../store/interfaces";
import { getModule } from "./solveConstraints";

const MIXED_PRICING = true;

export function getPricing(price: PricingData): number {
    return price.eur;
}
export function getPricingText(language: string, price: PricingData): string {
    let locale = 'de-DE';
    if (language === 'en') {
        locale = 'en-US';
    } else if(language === 'fr') {
        locale = 'fr-FR';
    }
    let temp = price.eur + 0.001;
    return temp.toLocaleString(locale, {style: 'currency', currency: 'EUR'});
}
export function getVat() : number {
    // Todo(daniel): Change based on country or something else?
    return 0.19;
}

export function addPrice(a: PricingData, b: PricingData) : PricingData {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    }, a);
    pricing.chf += b.chf;
    pricing.eur += b.eur;
    return pricing;
}

export function multiplyPrice(a: PricingData, b: number) : PricingData {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    }, a);
    pricing.chf *= b;
    pricing.eur *= b;
    return pricing;
}

export function getPocketPricing(library: ModuleLibraryData, pocket: PocketState, vat: boolean) {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    });

    if(MIXED_PRICING) {
        if(pocket.modules.length > 0) {
            pricing.eur = library.basePrice.eur;
            pricing.chf = library.basePrice.chf;
        }
    } else {
        const pricing: PricingData = Object.assign({
            chf: 0,
            eur: 0
        }, library.basePrice);
    
        pocket.modules.forEach((module) => {
            const data = getModule(library, module.article);
            if(data !== null) {
                pricing.chf += data.pricing.chf;
                pricing.eur += data.pricing.eur;
            }
        });
    }

    if(vat) {
        let vatFactor = getVat();
        pricing.chf += pricing.chf * vatFactor;
        pricing.eur += pricing.eur * vatFactor;
    }

    return pricing;
}

export function getFinalPricing(library: ModuleLibraryData, pockets: PocketState[], vat: boolean) {
    const pricing: PricingData = Object.assign({
        chf: 0,
        eur: 0
    });
    pockets.forEach((pocket) => {
        let pocketPrice = getPocketPricing(library, pocket, vat);
        pricing.eur += pocketPrice.eur * pocket.count;
        pricing.chf += pocketPrice.chf * pocket.count;
    });
    return pricing;
}


export function getShippingPrice(library: ModuleLibraryData, price: PricingData, vat: boolean) {
    const pricing: PricingData = {
        chf: 0,
        eur: 0
    };

    let vatFactor = vat ? (1.0 + getVat()): 1.0;

    for (let i = 0; i < library.shippingPrice.length; i++) {
        const shipping = library.shippingPrice[i];
        if(price.eur >= shipping.minPricing.eur * vatFactor) {
            pricing.eur = shipping.costs.eur * vatFactor;
            break;
        }
    }
    for (let i = 0; i < library.shippingPrice.length; i++) {
        const shipping = library.shippingPrice[i];
        if(price.chf >= shipping.minPricing.chf * vatFactor) {
            pricing.chf = shipping.costs.chf * vatFactor;
            break;
        }
    }

    return pricing;
}
