/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { useThree } from "@react-three/fiber";

type GroupProps = JSX.IntrinsicElements['group'];

type GLTFResult = GLTF & {
  nodes: {
    Weste: THREE.Mesh;
    Ebene: THREE.Mesh;
    Verschluss: THREE.Mesh;
    ["Weste-Innen"]: THREE.Mesh;
    LappenRechts: THREE.Mesh;
    LappenLinks: THREE.Mesh;
  };
  materials: {
    Mat_Weste: THREE.MeshStandardMaterial;
    Schatten: THREE.MeshStandardMaterial;
    Mat_Weste_Innen: THREE.MeshStandardMaterial;
    Mat_Stoff: THREE.MeshStandardMaterial;
  };
};

interface ModelProps extends GroupProps {
  children?: React.ReactNode;
  leftIsAttached: boolean;
  rightIsAttached: boolean;
}

const debug = false;

export default function Model(props: ModelProps) {
  const { gl } = useThree()
  
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF("./models/Weste.glb") as unknown as GLTFResult;

  //const leftBadge = useGLTF("./models/Lappen_Links.glb") as any;
  //const rightBadge = useGLTF("./models/Lappen_Rechts.glb") as any;

  //leftBadge.materials.Mat.map.repeat = new THREE.Vector2(3, 3);
  //rightBadge.materials.Mat.map.repeat = new THREE.Vector2(3, 3);

  if(materials.Mat_Weste.aoMap) {
    materials.Mat_Weste.alphaMap = materials.Mat_Weste.aoMap;
    materials.Mat_Weste.alphaMap.minFilter = THREE.LinearMipMapLinearFilter;
    materials.Mat_Weste.alphaMap.magFilter = THREE.LinearFilter;
    materials.Mat_Weste.alphaMap.anisotropy = gl.capabilities.getMaxAnisotropy();
    materials.Mat_Weste.alphaMap.generateMipmaps = true;
    materials.Mat_Weste.alphaMap.needsUpdate = true;

    if(materials.Mat_Weste.map) {
      materials.Mat_Weste.map.minFilter = THREE.LinearMipMapLinearFilter;
      materials.Mat_Weste.map.magFilter = THREE.LinearFilter;
      materials.Mat_Weste.map.anisotropy = gl.capabilities.getMaxAnisotropy();
      materials.Mat_Weste.map.generateMipmaps = true;
      materials.Mat_Weste.map.needsUpdate = true;
    }
    materials.Mat_Weste.aoMap = null;
    materials.Mat_Weste.alphaTest = 0;
    materials.Mat_Weste.transparent = true;
    materials.Mat_Weste.side = THREE.FrontSide;
  }

  if(materials.Mat_Weste_Innen.aoMap) {
    materials.Mat_Weste_Innen.alphaMap = materials.Mat_Weste_Innen.aoMap;
    materials.Mat_Weste_Innen.alphaMap.minFilter = THREE.LinearMipMapLinearFilter;
    materials.Mat_Weste_Innen.alphaMap.magFilter = THREE.LinearFilter;
    materials.Mat_Weste_Innen.alphaMap.anisotropy = gl.capabilities.getMaxAnisotropy();
    materials.Mat_Weste_Innen.alphaMap.generateMipmaps = true;
    materials.Mat_Weste_Innen.alphaMap.needsUpdate = true;

    if(materials.Mat_Weste_Innen.map) {
      materials.Mat_Weste_Innen.map.minFilter = THREE.LinearMipMapLinearFilter;
      materials.Mat_Weste_Innen.map.magFilter = THREE.LinearFilter;
      materials.Mat_Weste_Innen.map.anisotropy = gl.capabilities.getMaxAnisotropy();
      materials.Mat_Weste_Innen.map.generateMipmaps = true;
      materials.Mat_Weste_Innen.map.needsUpdate = true;
    }
    materials.Mat_Weste_Innen.aoMap = null;
    materials.Mat_Weste_Innen.alphaTest = 0;
    materials.Mat_Weste_Innen.transparent = true;
    materials.Mat_Weste_Innen.side = THREE.FrontSide;
  }

  if(materials.Schatten.aoMap) {
    materials.Schatten.alphaMap = materials.Schatten.aoMap;
    materials.Schatten.aoMap = null;
    materials.Schatten.transparent = true;
    materials.Schatten.color.setRGB(0, 0, 0);
    materials.Schatten.side = THREE.FrontSide;
  }

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="AuxScene">
        <group
          name="fc562e5f-d115-47cc-a0bd-d743a98c0cda"
          userData={{ name: "fc562e5f-d115-47cc-a0bd-d743a98c0cda" }}
        >
          <mesh
            name="Weste"
            geometry={nodes.Weste.geometry}
            material={materials.Mat_Weste}
            position={[0.023, 0.378, -0.004]}
            rotation={[0, -Math.PI / 2, 0]}
            scale={0.06}
            userData={{
              name: "Weste",
              associations: { meshes: 0, primitives: 0, nodes: 0 },
              variantData: {},
            }}
          >
            <mesh
              name="Verschluss"
              geometry={nodes.Verschluss.geometry}
              material={materials.Mat_Weste}
              userData={{
                name: "Verschluss",
                associations: { meshes: 1, primitives: 0, nodes: 1 },
                variantData: {},
              }}
            />
            {props.rightIsAttached &&
              <mesh
                name="LappenRechts"
                geometry={nodes.LappenRechts.geometry}
                material={materials.Mat_Stoff}
                position={[0.06, 0, 0.376]}
                userData={{
                  name: "LappenRechts",
                  associations: { meshes: 1, primitives: 0, nodes: 1 },
                  variantData: {},
                }}
              />
            }
            {props.leftIsAttached &&
              <mesh
                name="LappenLinks"
                geometry={nodes.LappenLinks.geometry}
                material={materials.Mat_Stoff}
                position={[0.06, 0, 0.376]}
                userData={{
                  name: "LappenLinks",
                  associations: { meshes: 2, primitives: 0, nodes: 2 },
                  variantData: {},
                }}
              />
            }
            <mesh
              name="Ebene"
              geometry={nodes.Ebene.geometry}
              material={materials.Schatten}
              position={[0.06, -6.243, 0.376]}
              rotation={[0, Math.PI / 2, 0]}
              scale={16.667}
              userData={{
                name: "Ebene",
                associations: { meshes: 1, primitives: 0, nodes: 1 },
                variantData: {},
              }}
            />
            <mesh
              name="Weste-Innen"
              geometry={nodes["Weste-Innen"].geometry}
              material={materials.Mat_Weste_Innen}
              userData={{
                name: "Weste-Innen",
                associations: { meshes: 2, primitives: 0, nodes: 2 },
                variantData: {},
              }}
            />
            
            <group
              name="Cargo_Links"
              position={[2.013, -0.219, 1.942]}
              rotation={[-0.029, 0.778, -0.056]}
              userData={{ name: "Cargo_Links", associations: { nodes: 5 } }}
            >
              <group
                name="Hose_L_0_0"
                position={[-1.599, 1.562, -0.822]}
                rotation={[0.048, -0.756, -3.074]}
                userData={{ name: "Hose_L_0_0", associations: { nodes: 7 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_1"
                position={[-1.489, 1.519, -0.734]}
                rotation={[0.048, -0.755, -3.074]}
                userData={{ name: "Hose_L_0_1", associations: { nodes: 8 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_2"
                position={[-1.285, 1.551, -0.534]}
                rotation={[0.043, -0.636, -3.081]}
                userData={{ name: "Hose_L_0_2", associations: { nodes: 9 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_3"
                position={[-1.02, 1.612, -0.379]}
                rotation={[0.039, -0.474, -3.089]}
                userData={{ name: "Hose_L_0_3", associations: { nodes: 10 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_4"
                position={[-0.775, 1.614, -0.267]}
                rotation={[0.038, -0.395, -3.092]}
                userData={{ name: "Hose_L_0_4", associations: { nodes: 11 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_5"
                position={[-0.523, 1.617, -0.172]}
                rotation={[0.038, -0.379, -3.093]}
                userData={{ name: "Hose_L_0_5", associations: { nodes: 12 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_6"
                position={[-0.287, 1.619, -0.074]}
                rotation={[0.037, -0.31, -3.095]}
                userData={{ name: "Hose_L_0_6", associations: { nodes: 13 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_7"
                position={[-0.043, 1.626, -0.02]}
                rotation={[0.035, -0.15, -3.101]}
                userData={{ name: "Hose_L_0_7", associations: { nodes: 14 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_8"
                position={[0.22, 1.639, 0.003]}
                rotation={[0.035, -0.033, -3.106]}
                userData={{ name: "Hose_L_0_8", associations: { nodes: 15 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_9"
                position={[0.497, 1.655, 0]}
                rotation={[0.035, -0.009, -3.106]}
                userData={{ name: "Hose_L_0_9", associations: { nodes: 16 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_10"
                position={[0.783, 1.708, 0.013]}
                rotation={[0.035, 0.126, -3.111]}
                userData={{ name: "Hose_L_0_10", associations: { nodes: 17 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_11"
                position={[1.153, 1.684, -0.062]}
                rotation={[0.04, 0.519, -3.127]}
                userData={{ name: "Hose_L_0_11", associations: { nodes: 18 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_0_12"
                position={[1.306, 1.695, -0.195]}
                rotation={[0.048, 0.755, -3.14]}
                userData={{ name: "Hose_L_0_12", associations: { nodes: 19 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_0"
                position={[-1.738, 1.309, -0.948]}
                rotation={[0.048, -0.765, -3.073]}
                userData={{ name: "Hose_L_1_0", associations: { nodes: 20 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_1"
                position={[-1.496, 1.327, -0.732]}
                rotation={[0.048, -0.757, -3.074]}
                userData={{ name: "Hose_L_1_1", associations: { nodes: 21 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_2"
                position={[-1.282, 1.329, -0.537]}
                rotation={[0.043, -0.632, -3.081]}
                userData={{ name: "Hose_L_1_2", associations: { nodes: 22 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_3"
                position={[-1.019, 1.331, -0.386]}
                rotation={[0.039, -0.472, -3.089]}
                userData={{ name: "Hose_L_1_3", associations: { nodes: 23 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_4"
                position={[-0.774, 1.341, -0.273]}
                rotation={[0.038, -0.395, -3.092]}
                userData={{ name: "Hose_L_1_4", associations: { nodes: 24 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_5"
                position={[-0.525, 1.374, -0.177]}
                rotation={[0.038, -0.382, -3.093]}
                userData={{ name: "Hose_L_1_5", associations: { nodes: 25 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_6"
                position={[-0.292, 1.395, -0.077]}
                rotation={[0.037, -0.307, -3.096]}
                userData={{ name: "Hose_L_1_6", associations: { nodes: 26 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_7"
                position={[-0.049, 1.391, -0.021]}
                rotation={[0.035, -0.145, -3.102]}
                userData={{ name: "Hose_L_1_7", associations: { nodes: 27 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_8"
                position={[0.216, 1.421, -0.001]}
                rotation={[0.035, -0.031, -3.106]}
                userData={{ name: "Hose_L_1_8", associations: { nodes: 28 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_9"
                position={[0.496, 1.451, -0.005]}
                rotation={[0.035, 0.016, -3.107]}
                userData={{ name: "Hose_L_1_9", associations: { nodes: 29 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_10"
                position={[0.79, 1.442, -0.006]}
                rotation={[0.035, 0.157, -3.112]}
                userData={{ name: "Hose_L_1_10", associations: { nodes: 30 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_11"
                position={[1.159, 1.438, -0.122]}
                rotation={[0.041, 0.542, -3.128]}
                userData={{ name: "Hose_L_1_11", associations: { nodes: 31 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_1_12"
                position={[1.454, 1.391, -0.421]}
                rotation={[0.049, 0.786, 3.142]}
                userData={{ name: "Hose_L_1_12", associations: { nodes: 32 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_0"
                position={[-1.724, 1.062, -0.936]}
                rotation={[0.051, -0.817, -3.069]}
                userData={{ name: "Hose_L_2_0", associations: { nodes: 33 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_1"
                position={[-1.506, 1.046, -0.738]}
                rotation={[0.048, -0.764, -3.073]}
                userData={{ name: "Hose_L_2_1", associations: { nodes: 34 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_2"
                position={[-1.293, 1.047, -0.538]}
                rotation={[0.043, -0.608, -3.082]}
                userData={{ name: "Hose_L_2_2", associations: { nodes: 35 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_3"
                position={[-1.025, 1.049, -0.389]}
                rotation={[0.039, -0.463, -3.089]}
                userData={{ name: "Hose_L_2_3", associations: { nodes: 36 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_4"
                position={[-0.778, 1.058, -0.278]}
                rotation={[0.038, -0.393, -3.092]}
                userData={{ name: "Hose_L_2_4", associations: { nodes: 37 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_5"
                position={[-0.531, 1.089, -0.18]}
                rotation={[0.038, -0.386, -3.092]}
                userData={{ name: "Hose_L_2_5", associations: { nodes: 38 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_6"
                position={[-0.298, 1.106, -0.08]}
                rotation={[0.036, -0.292, -3.096]}
                userData={{ name: "Hose_L_2_6", associations: { nodes: 39 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_7"
                position={[-0.046, 1.102, -0.033]}
                rotation={[0.035, -0.124, -3.102]}
                userData={{ name: "Hose_L_2_7", associations: { nodes: 40 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_8"
                position={[0.222, 1.125, -0.012]}
                rotation={[0.035, -0.024, -3.106]}
                userData={{ name: "Hose_L_2_8", associations: { nodes: 41 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_9"
                position={[0.505, 1.151, -0.017]}
                rotation={[0.035, 0.051, -3.108]}
                userData={{ name: "Hose_L_2_9", associations: { nodes: 42 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_10"
                position={[0.825, 1.145, -0.065]}
                rotation={[0.036, 0.203, -3.114]}
                userData={{ name: "Hose_L_2_10", associations: { nodes: 43 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_11"
                position={[1.164, 1.139, -0.152]}
                rotation={[0.042, 0.592, -3.13]}
                userData={{ name: "Hose_L_2_11", associations: { nodes: 44 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_2_12"
                position={[1.426, 1.158, -0.454]}
                rotation={[0.053, 0.857, 3.136]}
                userData={{ name: "Hose_L_2_12", associations: { nodes: 45 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_0"
                position={[-1.71, 0.783, -0.939]}
                rotation={[0.054, -0.87, -3.065]}
                userData={{ name: "Hose_L_3_0", associations: { nodes: 46 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_1"
                position={[-1.544, 0.763, -0.701]}
                rotation={[0.049, -0.773, -3.073]}
                userData={{ name: "Hose_L_3_1", associations: { nodes: 47 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_2"
                position={[-1.311, 0.765, -0.54]}
                rotation={[0.042, -0.585, -3.084]}
                userData={{ name: "Hose_L_3_2", associations: { nodes: 48 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_3"
                position={[-1.032, 0.768, -0.391]}
                rotation={[0.039, -0.453, -3.09]}
                userData={{ name: "Hose_L_3_3", associations: { nodes: 49 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_4"
                position={[-0.783, 0.777, -0.282]}
                rotation={[0.038, -0.387, -3.092]}
                userData={{ name: "Hose_L_3_4", associations: { nodes: 50 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_5"
                position={[-0.533, 0.808, -0.187]}
                rotation={[0.038, -0.383, -3.093]}
                userData={{ name: "Hose_L_3_5", associations: { nodes: 51 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_6"
                position={[-0.303, 0.825, -0.083]}
                rotation={[0.036, -0.266, -3.097]}
                userData={{ name: "Hose_L_3_6", associations: { nodes: 52 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_7"
                position={[-0.035, 0.822, -0.051]}
                rotation={[0.035, -0.094, -3.103]}
                userData={{ name: "Hose_L_3_7", associations: { nodes: 53 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_8"
                position={[0.239, 0.846, -0.034]}
                rotation={[0.035, -0.013, -3.106]}
                userData={{ name: "Hose_L_3_8", associations: { nodes: 54 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_9"
                position={[0.525, 0.872, -0.043]}
                rotation={[0.035, 0.058, -3.109]}
                userData={{ name: "Hose_L_3_9", associations: { nodes: 55 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_10"
                position={[0.829, 0.865, -0.067]}
                rotation={[0.036, 0.232, -3.115]}
                userData={{ name: "Hose_L_3_10", associations: { nodes: 56 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_11"
                position={[1.185, 0.861, -0.204]}
                rotation={[0.044, 0.658, -3.134]}
                userData={{ name: "Hose_L_3_11", associations: { nodes: 57 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_3_12"
                position={[1.399, 0.876, -0.497]}
                rotation={[0.059, 0.933, 3.129]}
                userData={{ name: "Hose_L_3_12", associations: { nodes: 58 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_0"
                position={[-1.719, 0.489, -0.919]}
                rotation={[0.052, -0.829, -3.069]}
                userData={{ name: "Hose_L_4_0", associations: { nodes: 59 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_1"
                position={[-1.517, 0.482, -0.727]}
                rotation={[0.048, -0.76, -3.073]}
                userData={{ name: "Hose_L_4_1", associations: { nodes: 60 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_2"
                position={[-1.311, 0.484, -0.539]}
                rotation={[0.042, -0.592, -3.083]}
                userData={{ name: "Hose_L_4_2", associations: { nodes: 61 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_3"
                position={[-1.037, 0.487, -0.394]}
                rotation={[0.039, -0.455, -3.09]}
                userData={{ name: "Hose_L_4_3", associations: { nodes: 62 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_4"
                position={[-0.789, 0.495, -0.284]}
                rotation={[0.038, -0.385, -3.093]}
                userData={{ name: "Hose_L_4_4", associations: { nodes: 63 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_5"
                position={[-0.539, 0.526, -0.19]}
                rotation={[0.037, -0.36, -3.094]}
                userData={{ name: "Hose_L_4_5", associations: { nodes: 64 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_6"
                position={[-0.301, 0.545, -0.094]}
                rotation={[0.036, -0.231, -3.098]}
                userData={{ name: "Hose_L_4_6", associations: { nodes: 65 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_7"
                position={[-0.022, 0.542, -0.071]}
                rotation={[0.035, -0.074, -3.104]}
                userData={{ name: "Hose_L_4_7", associations: { nodes: 66 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_8"
                position={[0.252, 0.567, -0.055]}
                rotation={[0.035, 0.002, -3.107]}
                userData={{ name: "Hose_L_4_8", associations: { nodes: 67 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_9"
                position={[0.548, 0.594, -0.072]}
                rotation={[0.035, 0.059, -3.109]}
                userData={{ name: "Hose_L_4_9", associations: { nodes: 68 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_10"
                position={[0.854, 0.587, -0.098]}
                rotation={[0.036, 0.257, -3.116]}
                userData={{ name: "Hose_L_4_10", associations: { nodes: 69 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_11"
                position={[1.191, 0.581, -0.245]}
                rotation={[0.047, 0.725, -3.138]}
                userData={{ name: "Hose_L_4_11", associations: { nodes: 70 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_4_12"
                position={[1.378, 0.595, -0.535]}
                rotation={[0.064, 0.997, 3.122]}
                userData={{ name: "Hose_L_4_12", associations: { nodes: 71 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_0"
                position={[-1.713, 0.203, -0.913]}
                rotation={[0.049, -0.769, -3.073]}
                userData={{ name: "Hose_L_5_0", associations: { nodes: 72 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_1"
                position={[-1.53, 0.201, -0.734]}
                rotation={[0.047, -0.728, -3.076]}
                userData={{ name: "Hose_L_5_1", associations: { nodes: 73 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_2"
                position={[-1.301, 0.203, -0.548]}
                rotation={[0.042, -0.601, -3.083]}
                userData={{ name: "Hose_L_5_2", associations: { nodes: 74 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_3"
                position={[-1.039, 0.206, -0.401]}
                rotation={[0.039, -0.48, -3.089]}
                userData={{ name: "Hose_L_5_3", associations: { nodes: 75 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_4"
                position={[-0.793, 0.214, -0.289]}
                rotation={[0.038, -0.396, -3.092]}
                userData={{ name: "Hose_L_5_4", associations: { nodes: 76 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_5"
                position={[-0.542, 0.245, -0.195]}
                rotation={[0.037, -0.32, -3.095]}
                userData={{ name: "Hose_L_5_5", associations: { nodes: 77 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_6"
                position={[-0.284, 0.265, -0.119]}
                rotation={[0.036, -0.2, -3.1]}
                userData={{ name: "Hose_L_5_6", associations: { nodes: 78 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_7"
                position={[-0.011, 0.263, -0.09]}
                rotation={[0.035, -0.075, -3.104]}
                userData={{ name: "Hose_L_5_7", associations: { nodes: 79 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_8"
                position={[0.268, 0.287, -0.078]}
                rotation={[0.035, 0.019, -3.107]}
                userData={{ name: "Hose_L_5_8", associations: { nodes: 80 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_9"
                position={[0.568, 0.315, -0.1]}
                rotation={[0.035, 0.066, -3.109]}
                userData={{ name: "Hose_L_5_9", associations: { nodes: 81 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_10"
                position={[0.868, 0.307, -0.12]}
                rotation={[0.036, 0.282, -3.117]}
                userData={{ name: "Hose_L_5_10", associations: { nodes: 82 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_11"
                position={[1.19, 0.301, -0.287]}
                rotation={[0.049, 0.778, -3.141]}
                userData={{ name: "Hose_L_5_11", associations: { nodes: 83 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_5_12"
                position={[1.36, 0.315, -0.585]}
                rotation={[0.069, 1.043, 3.117]}
                userData={{ name: "Hose_L_5_12", associations: { nodes: 84 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_0"
                position={[-1.691, -0.07, -0.924]}
                rotation={[0.048, -0.765, -3.073]}
                userData={{ name: "Hose_L_6_0", associations: { nodes: 85 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_1"
                position={[-1.499, -0.078, -0.759]}
                rotation={[0.046, -0.698, -3.077]}
                userData={{ name: "Hose_L_6_1", associations: { nodes: 86 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_2"
                position={[-1.275, -0.074, -0.589]}
                rotation={[0.042, -0.596, -3.083]}
                userData={{ name: "Hose_L_6_2", associations: { nodes: 87 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_3"
                position={[-1.017, -0.073, -0.432]}
                rotation={[0.04, -0.527, -3.086]}
                userData={{ name: "Hose_L_6_3", associations: { nodes: 88 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_4"
                position={[-0.789, -0.066, -0.302]}
                rotation={[0.038, -0.41, -3.092]}
                userData={{ name: "Hose_L_6_4", associations: { nodes: 89 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_5"
                position={[-0.533, -0.035, -0.213]}
                rotation={[0.036, -0.286, -3.096]}
                userData={{ name: "Hose_L_6_5", associations: { nodes: 90 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_6"
                position={[-0.263, -0.013, -0.149]}
                rotation={[0.035, -0.18, -3.1]}
                userData={{ name: "Hose_L_6_6", associations: { nodes: 91 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_7"
                position={[0.005, -0.017, -0.114]}
                rotation={[0.035, -0.072, -3.104]}
                userData={{ name: "Hose_L_6_7", associations: { nodes: 92 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_8"
                position={[0.28, 0.008, -0.103]}
                rotation={[0.035, 0.06, -3.109]}
                userData={{ name: "Hose_L_6_8", associations: { nodes: 93 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_9"
                position={[0.58, 0.036, -0.136]}
                rotation={[0.035, 0.096, -3.11]}
                userData={{ name: "Hose_L_6_9", associations: { nodes: 94 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_10"
                position={[0.875, 0.027, -0.153]}
                rotation={[0.036, 0.279, -3.117]}
                userData={{ name: "Hose_L_6_10", associations: { nodes: 95 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_11"
                position={[1.183, 0.021, -0.33]}
                rotation={[0.05, 0.79, 3.141]}
                userData={{ name: "Hose_L_6_11", associations: { nodes: 96 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_6_12"
                position={[1.331, 0.033, -0.596]}
                rotation={[0.072, 1.063, 3.114]}
                userData={{ name: "Hose_L_6_12", associations: { nodes: 97 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_0"
                position={[-1.663, -0.357, -0.947]}
                rotation={[0.049, -0.779, -3.072]}
                userData={{ name: "Hose_L_7_0", associations: { nodes: 98 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_1"
                position={[-1.491, -0.358, -0.769]}
                rotation={[0.045, -0.685, -3.078]}
                userData={{ name: "Hose_L_7_1", associations: { nodes: 99 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_2"
                position={[-1.248, -0.353, -0.622]}
                rotation={[0.043, -0.609, -3.082]}
                userData={{ name: "Hose_L_7_2", associations: { nodes: 100 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_3"
                position={[-1.012, -0.353, -0.446]}
                rotation={[0.042, -0.573, -3.084]}
                userData={{ name: "Hose_L_7_3", associations: { nodes: 101 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_4"
                position={[-0.783, -0.346, -0.317]}
                rotation={[0.038, -0.401, -3.092]}
                userData={{ name: "Hose_L_7_4", associations: { nodes: 102 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_5"
                position={[-0.525, -0.314, -0.236]}
                rotation={[0.036, -0.266, -3.097]}
                userData={{ name: "Hose_L_7_5", associations: { nodes: 103 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_6"
                position={[-0.251, -0.293, -0.169]}
                rotation={[0.035, -0.173, -3.101]}
                userData={{ name: "Hose_L_7_6", associations: { nodes: 104 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_7"
                position={[0.021, -0.296, -0.143]}
                rotation={[0.035, -0.045, -3.105]}
                userData={{ name: "Hose_L_7_7", associations: { nodes: 105 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_8"
                position={[0.284, -0.272, -0.143]}
                rotation={[0.035, 0.128, -3.111]}
                userData={{ name: "Hose_L_7_8", associations: { nodes: 106 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_9"
                position={[0.561, -0.245, -0.226]}
                rotation={[0.035, 0.142, -3.112]}
                userData={{ name: "Hose_L_7_9", associations: { nodes: 107 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_10"
                position={[0.849, -0.254, -0.24]}
                rotation={[0.036, 0.247, -3.115]}
                userData={{ name: "Hose_L_7_10", associations: { nodes: 108 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_11"
                position={[1.175, -0.26, -0.362]}
                rotation={[0.048, 0.751, -3.139]}
                userData={{ name: "Hose_L_7_11", associations: { nodes: 109 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_7_12"
                position={[1.319, -0.248, -0.624]}
                rotation={[0.069, 1.041, 3.117]}
                userData={{ name: "Hose_L_7_12", associations: { nodes: 110 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_0"
                position={[-1.617, -0.635, -0.941]}
                rotation={[0.049, -0.776, -3.072]}
                userData={{ name: "Hose_L_8_0", associations: { nodes: 111 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_1"
                position={[-1.446, -0.638, -0.788]}
                rotation={[0.046, -0.702, -3.077]}
                userData={{ name: "Hose_L_8_1", associations: { nodes: 112 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_2"
                position={[-1.239, -0.633, -0.636]}
                rotation={[0.043, -0.634, -3.081]}
                userData={{ name: "Hose_L_8_2", associations: { nodes: 113 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_3"
                position={[-1.024, -0.634, -0.46]}
                rotation={[0.042, -0.59, -3.083]}
                userData={{ name: "Hose_L_8_3", associations: { nodes: 114 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_4"
                position={[-0.792, -0.628, -0.322]}
                rotation={[0.038, -0.393, -3.092]}
                userData={{ name: "Hose_L_8_4", associations: { nodes: 115 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_5"
                position={[-0.532, -0.595, -0.265]}
                rotation={[0.036, -0.257, -3.098]}
                userData={{ name: "Hose_L_8_5", associations: { nodes: 116 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_6"
                position={[-0.252, -0.574, -0.182]}
                rotation={[0.035, -0.157, -3.101]}
                userData={{ name: "Hose_L_8_6", associations: { nodes: 117 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_7"
                position={[0.027, -0.576, -0.162]}
                rotation={[0.035, -0.007, -3.106]}
                userData={{ name: "Hose_L_8_7", associations: { nodes: 118 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_8"
                position={[0.311, -0.55, -0.18]}
                rotation={[0.035, 0.16, -3.112]}
                userData={{ name: "Hose_L_8_8", associations: { nodes: 119 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_9"
                position={[0.583, -0.523, -0.261]}
                rotation={[0.035, 0.164, -3.112]}
                userData={{ name: "Hose_L_8_9", associations: { nodes: 120 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_10"
                position={[0.862, -0.533, -0.273]}
                rotation={[0.036, 0.245, -3.115]}
                userData={{ name: "Hose_L_8_10", associations: { nodes: 121 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_11"
                position={[1.135, -0.541, -0.397]}
                rotation={[0.046, 0.712, -3.137]}
                userData={{ name: "Hose_L_8_11", associations: { nodes: 122 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_8_12"
                position={[1.297, -0.53, -0.628]}
                rotation={[0.062, 0.973, 3.125]}
                userData={{ name: "Hose_L_8_12", associations: { nodes: 123 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_0"
                position={[-1.609, -0.925, -0.954]}
                rotation={[0.051, -0.819, -3.069]}
                userData={{ name: "Hose_L_9_0", associations: { nodes: 124 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_1"
                position={[-1.431, -0.92, -0.784]}
                rotation={[0.047, -0.728, -3.076]}
                userData={{ name: "Hose_L_9_1", associations: { nodes: 125 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_2"
                position={[-1.248, -0.916, -0.624]}
                rotation={[0.043, -0.611, -3.082]}
                userData={{ name: "Hose_L_9_2", associations: { nodes: 126 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_3"
                position={[-1.012, -0.917, -0.479]}
                rotation={[0.041, -0.571, -3.084]}
                userData={{ name: "Hose_L_9_3", associations: { nodes: 127 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_4"
                position={[-0.79, -0.914, -0.333]}
                rotation={[0.038, -0.41, -3.092]}
                userData={{ name: "Hose_L_9_4", associations: { nodes: 128 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_5"
                position={[-0.54, -0.883, -0.265]}
                rotation={[0.036, -0.271, -3.097]}
                userData={{ name: "Hose_L_9_5", associations: { nodes: 129 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_6"
                position={[-0.257, -0.863, -0.188]}
                rotation={[0.035, -0.129, -3.102]}
                userData={{ name: "Hose_L_9_6", associations: { nodes: 130 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_7"
                position={[0.021, -0.864, -0.202]}
                rotation={[0.035, 0.009, -3.107]}
                userData={{ name: "Hose_L_9_7", associations: { nodes: 131 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_8"
                position={[0.317, -0.838, -0.201]}
                rotation={[0.035, 0.111, -3.111]}
                userData={{ name: "Hose_L_9_8", associations: { nodes: 132 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_9"
                position={[0.594, -0.808, -0.278]}
                rotation={[0.035, 0.15, -3.112]}
                userData={{ name: "Hose_L_9_9", associations: { nodes: 133 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_10"
                position={[0.875, -0.812, -0.306]}
                rotation={[0.036, 0.291, -3.117]}
                userData={{ name: "Hose_L_9_10", associations: { nodes: 134 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_11"
                position={[1.153, -0.821, -0.431]}
                rotation={[0.046, 0.704, -3.136]}
                userData={{ name: "Hose_L_9_11", associations: { nodes: 135 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_9_12"
                position={[1.299, -0.811, -0.616]}
                rotation={[0.056, 0.902, 3.132]}
                userData={{ name: "Hose_L_9_12", associations: { nodes: 136 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_0"
                position={[-1.617, -1.213, -0.951]}
                rotation={[0.054, -0.864, -3.066]}
                userData={{ name: "Hose_L_10_0", associations: { nodes: 137 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_1"
                position={[-1.44, -1.209, -0.753]}
                rotation={[0.045, -0.687, -3.078]}
                userData={{ name: "Hose_L_10_1", associations: { nodes: 138 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_2"
                position={[-1.222, -1.21, -0.637]}
                rotation={[0.041, -0.539, -3.086]}
                userData={{ name: "Hose_L_10_2", associations: { nodes: 139 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_3"
                position={[-0.997, -1.212, -0.496]}
                rotation={[0.041, -0.567, -3.084]}
                userData={{ name: "Hose_L_10_3", associations: { nodes: 140 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_4"
                position={[-0.763, -1.209, -0.361]}
                rotation={[0.039, -0.46, -3.089]}
                userData={{ name: "Hose_L_10_4", associations: { nodes: 141 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_5"
                position={[-0.525, -1.179, -0.28]}
                rotation={[0.037, -0.318, -3.095]}
                userData={{ name: "Hose_L_10_5", associations: { nodes: 142 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_6"
                position={[-0.253, -1.16, -0.194]}
                rotation={[0.035, -0.124, -3.102]}
                userData={{ name: "Hose_L_10_6", associations: { nodes: 143 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_7"
                position={[0.027, -1.162, -0.208]}
                rotation={[0.035, -0.002, -3.107]}
                userData={{ name: "Hose_L_10_7", associations: { nodes: 144 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_8"
                position={[0.333, -1.135, -0.186]}
                rotation={[0.035, 0.047, -3.108]}
                userData={{ name: "Hose_L_10_8", associations: { nodes: 145 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_9"
                position={[0.63, -1.102, -0.209]}
                rotation={[0.035, 0.144, -3.112]}
                userData={{ name: "Hose_L_10_9", associations: { nodes: 146 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_10"
                position={[0.906, -1.1, -0.257]}
                rotation={[0.037, 0.365, -3.12]}
                userData={{
                  name: "Hose_L_10_10",
                  associations: { nodes: 147 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_11"
                position={[1.159, -1.1, -0.418]}
                rotation={[0.045, 0.669, -3.134]}
                userData={{
                  name: "Hose_L_10_11",
                  associations: { nodes: 148 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_10_12"
                position={[1.285, -1.092, -0.56]}
                rotation={[0.049, 0.767, -3.14]}
                userData={{
                  name: "Hose_L_10_12",
                  associations: { nodes: 149 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_0"
                position={[-1.59, -1.582, -0.946]}
                rotation={[0.044, -0.666, -3.079]}
                userData={{ name: "Hose_L_11_0", associations: { nodes: 150 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_1"
                position={[-1.398, -1.559, -0.773]}
                rotation={[0.04, -0.529, -3.086]}
                userData={{ name: "Hose_L_11_1", associations: { nodes: 151 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_2"
                position={[-1.159, -1.552, -0.688]}
                rotation={[0.04, -0.517, -3.087]}
                userData={{ name: "Hose_L_11_2", associations: { nodes: 152 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_3"
                position={[-0.953, -1.524, -0.538]}
                rotation={[0.043, -0.626, -3.081]}
                userData={{ name: "Hose_L_11_3", associations: { nodes: 153 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_4"
                position={[-0.739, -1.504, -0.38]}
                rotation={[0.041, -0.543, -3.086]}
                userData={{ name: "Hose_L_11_4", associations: { nodes: 154 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_5"
                position={[-0.521, -1.477, -0.256]}
                rotation={[0.038, -0.379, -3.093]}
                userData={{ name: "Hose_L_11_5", associations: { nodes: 155 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_6"
                position={[-0.254, -1.458, -0.185]}
                rotation={[0.035, -0.143, -3.102]}
                userData={{ name: "Hose_L_11_6", associations: { nodes: 156 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_7"
                position={[0.04, -1.459, -0.181]}
                rotation={[0.035, -0.009, -3.106]}
                userData={{ name: "Hose_L_11_7", associations: { nodes: 157 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_8"
                position={[0.334, -1.433, -0.18]}
                rotation={[0.035, 0.041, -3.108]}
                userData={{ name: "Hose_L_11_8", associations: { nodes: 158 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_9"
                position={[0.642, -1.396, -0.214]}
                rotation={[0.035, 0.177, -3.113]}
                userData={{ name: "Hose_L_11_9", associations: { nodes: 159 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_10"
                position={[0.893, -1.391, -0.297]}
                rotation={[0.038, 0.409, -3.122]}
                userData={{
                  name: "Hose_L_11_10",
                  associations: { nodes: 160 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_11"
                position={[1.138, -1.387, -0.437]}
                rotation={[0.041, 0.571, -3.129]}
                userData={{
                  name: "Hose_L_11_11",
                  associations: { nodes: 161 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_11_12"
                position={[1.27, -1.374, -0.497]}
                rotation={[0.042, 0.582, -3.13]}
                userData={{
                  name: "Hose_L_11_12",
                  associations: { nodes: 162 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_0"
                position={[-1.59, -1.908, -0.898]}
                rotation={[0.039, -0.475, -3.089]}
                userData={{ name: "Hose_L_12_0", associations: { nodes: 163 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_1"
                position={[-1.318, -1.907, -0.832]}
                rotation={[0.038, -0.422, -3.091]}
                userData={{ name: "Hose_L_12_1", associations: { nodes: 164 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_2"
                position={[-1.097, -1.889, -0.735]}
                rotation={[0.041, -0.534, -3.086]}
                userData={{ name: "Hose_L_12_2", associations: { nodes: 165 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_3"
                position={[-0.935, -1.863, -0.566]}
                rotation={[0.045, -0.671, -3.079]}
                userData={{ name: "Hose_L_12_3", associations: { nodes: 166 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_4"
                position={[-0.723, -1.838, -0.399]}
                rotation={[0.042, -0.589, -3.083]}
                userData={{ name: "Hose_L_12_4", associations: { nodes: 167 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_5"
                position={[-0.51, -1.828, -0.265]}
                rotation={[0.038, -0.41, -3.091]}
                userData={{ name: "Hose_L_12_5", associations: { nodes: 168 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_6"
                position={[-0.256, -1.799, -0.182]}
                rotation={[0.035, -0.153, -3.101]}
                userData={{ name: "Hose_L_12_6", associations: { nodes: 169 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_7"
                position={[0.034, -1.795, -0.173]}
                rotation={[0.035, -0.008, -3.106]}
                userData={{ name: "Hose_L_12_7", associations: { nodes: 170 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_8"
                position={[0.329, -1.791, -0.178]}
                rotation={[0.035, 0.052, -3.109]}
                userData={{ name: "Hose_L_12_8", associations: { nodes: 171 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_9"
                position={[0.667, -1.744, -0.208]}
                rotation={[0.036, 0.2, -3.114]}
                userData={{ name: "Hose_L_12_9", associations: { nodes: 172 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_10"
                position={[0.91, -1.633, -0.293]}
                rotation={[0.038, 0.409, -3.122]}
                userData={{
                  name: "Hose_L_12_10",
                  associations: { nodes: 173 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_11"
                position={[1.151, -1.503, -0.423]}
                rotation={[0.04, 0.509, -3.126]}
                userData={{
                  name: "Hose_L_12_11",
                  associations: { nodes: 174 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_L_12_12"
                position={[1.221, -1.469, -0.477]}
                rotation={[0.04, 0.501, -3.126]}
                userData={{
                  name: "Hose_L_12_12",
                  associations: { nodes: 175 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
            </group>
            <group
              name="Cargo_Rechts"
              position={[-1.639, -0.422, 2.255]}
              rotation={[-0.061, 1.142, 0.043]}
              userData={{ name: "Cargo_Rechts", associations: { nodes: 6 } }}
            >
              <group
                name="Hose_R_0_0"
                position={[0.418, 1.727, 1.306]}
                rotation={[0.042, -1.137, -3.086]}
                scale={16.667}
                userData={{ name: "Hose_R_0_0", associations: { nodes: 176 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_1"
                position={[0.361, 1.857, 1.194]}
                rotation={[0.044, -1.165, -3.083]}
                scale={16.667}
                userData={{ name: "Hose_R_0_1", associations: { nodes: 177 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_2"
                position={[0.206, 1.88, 0.828]}
                rotation={[0.053, -1.232, -3.075]}
                scale={16.667}
                userData={{ name: "Hose_R_0_2", associations: { nodes: 178 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_3"
                position={[0.106, 1.895, 0.488]}
                rotation={[0.083, -1.357, -3.043]}
                scale={16.667}
                userData={{ name: "Hose_R_0_3", associations: { nodes: 179 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_4"
                position={[0.079, 1.896, 0.206]}
                rotation={[0.529, -1.536, -2.595]}
                scale={16.667}
                userData={{ name: "Hose_R_0_4", associations: { nodes: 180 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_5"
                position={[0.089, 1.902, -0.12]}
                rotation={[2.953, -1.478, -0.171]}
                scale={16.667}
                userData={{ name: "Hose_R_0_5", associations: { nodes: 181 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_6"
                position={[0.122, 1.905, -0.401]}
                rotation={[3.064, -1.345, -0.058]}
                scale={16.667}
                userData={{ name: "Hose_R_0_6", associations: { nodes: 182 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_7"
                position={[0.21, 1.912, -0.647]}
                rotation={[3.085, -1.257, -0.036]}
                scale={16.667}
                userData={{ name: "Hose_R_0_7", associations: { nodes: 183 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_8"
                position={[0.306, 1.923, -0.966]}
                rotation={[3.096, -1.176, -0.024]}
                scale={16.667}
                userData={{ name: "Hose_R_0_8", associations: { nodes: 184 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_9"
                position={[0.435, 1.938, -1.254]}
                rotation={[3.107, -1.05, -0.013]}
                scale={16.667}
                userData={{ name: "Hose_R_0_9", associations: { nodes: 185 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_10"
                position={[0.575, 1.949, -1.46]}
                rotation={[3.115, -0.847, -0.002]}
                scale={16.667}
                userData={{ name: "Hose_R_0_10", associations: { nodes: 186 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_11"
                position={[0.794, 1.945, -1.647]}
                rotation={[3.121, -0.574, 0.006]}
                scale={16.667}
                userData={{ name: "Hose_R_0_11", associations: { nodes: 187 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_0_12"
                position={[0.832, 1.946, -1.656]}
                rotation={[3.122, -0.476, 0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_0_12", associations: { nodes: 188 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_0"
                position={[0.455, 1.555, 1.462]}
                rotation={[0.043, -1.152, -3.085]}
                scale={16.667}
                userData={{ name: "Hose_R_1_0", associations: { nodes: 189 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_1"
                position={[0.345, 1.593, 1.172]}
                rotation={[0.045, -1.172, -3.083]}
                scale={16.667}
                userData={{ name: "Hose_R_1_1", associations: { nodes: 190 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_2"
                position={[0.225, 1.616, 0.851]}
                rotation={[0.052, -1.225, -3.076]}
                scale={16.667}
                userData={{ name: "Hose_R_1_2", associations: { nodes: 191 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_3"
                position={[0.11, 1.654, 0.509]}
                rotation={[0.081, -1.352, -3.046]}
                scale={16.667}
                userData={{ name: "Hose_R_1_3", associations: { nodes: 192 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_4"
                position={[0.075, 1.643, 0.201]}
                rotation={[0.505, -1.535, -2.62]}
                scale={16.667}
                userData={{ name: "Hose_R_1_4", associations: { nodes: 193 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_5"
                position={[0.083, 1.647, -0.128]}
                rotation={[2.95, -1.479, -0.174]}
                scale={16.667}
                userData={{ name: "Hose_R_1_5", associations: { nodes: 194 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_6"
                position={[0.116, 1.648, -0.408]}
                rotation={[3.061, -1.351, -0.061]}
                scale={16.667}
                userData={{ name: "Hose_R_1_6", associations: { nodes: 195 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_7"
                position={[0.201, 1.652, -0.662]}
                rotation={[3.085, -1.254, -0.036]}
                scale={16.667}
                userData={{ name: "Hose_R_1_7", associations: { nodes: 196 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_8"
                position={[0.296, 1.653, -0.978]}
                rotation={[3.099, -1.148, -0.021]}
                scale={16.667}
                userData={{ name: "Hose_R_1_8", associations: { nodes: 197 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_9"
                position={[0.457, 1.65, -1.227]}
                rotation={[3.108, -1.021, -0.011]}
                scale={16.667}
                userData={{ name: "Hose_R_1_9", associations: { nodes: 198 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_10"
                position={[0.581, 1.67, -1.452]}
                rotation={[3.116, -0.823, -0.001]}
                scale={16.667}
                userData={{ name: "Hose_R_1_10", associations: { nodes: 199 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_11"
                position={[0.812, 1.658, -1.608]}
                rotation={[3.121, -0.567, 0.006]}
                scale={16.667}
                userData={{ name: "Hose_R_1_11", associations: { nodes: 200 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_1_12"
                position={[1.063, 1.584, -1.724]}
                rotation={[3.122, -0.489, 0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_1_12", associations: { nodes: 201 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_0"
                position={[0.466, 1.311, 1.467]}
                rotation={[0.041, -1.132, -3.087]}
                scale={16.667}
                userData={{ name: "Hose_R_2_0", associations: { nodes: 202 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_1"
                position={[0.325, 1.351, 1.169]}
                rotation={[0.044, -1.162, -3.084]}
                scale={16.667}
                userData={{ name: "Hose_R_2_1", associations: { nodes: 203 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_2"
                position={[0.182, 1.362, 0.807]}
                rotation={[0.052, -1.226, -3.076]}
                scale={16.667}
                userData={{ name: "Hose_R_2_2", associations: { nodes: 204 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_3"
                position={[0.088, 1.356, 0.5]}
                rotation={[0.081, -1.353, -3.045]}
                scale={16.667}
                userData={{ name: "Hose_R_2_3", associations: { nodes: 205 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_4"
                position={[0.06, 1.344, 0.189]}
                rotation={[0.471, -1.532, -2.653]}
                scale={16.667}
                userData={{ name: "Hose_R_2_4", associations: { nodes: 206 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_5"
                position={[0.066, 1.346, -0.141]}
                rotation={[2.946, -1.481, -0.177]}
                scale={16.667}
                userData={{ name: "Hose_R_2_5", associations: { nodes: 207 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_6"
                position={[0.101, 1.345, -0.422]}
                rotation={[3.06, -1.355, -0.062]}
                scale={16.667}
                userData={{ name: "Hose_R_2_6", associations: { nodes: 208 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_7"
                position={[0.178, 1.343, -0.682]}
                rotation={[3.089, -1.236, -0.033]}
                scale={16.667}
                userData={{ name: "Hose_R_2_7", associations: { nodes: 209 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_8"
                position={[0.292, 1.348, -0.977]}
                rotation={[3.103, -1.1, -0.017]}
                scale={16.667}
                userData={{ name: "Hose_R_2_8", associations: { nodes: 210 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_9"
                position={[0.449, 1.354, -1.229]}
                rotation={[3.111, -0.971, -0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_2_9", associations: { nodes: 211 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_10"
                position={[0.609, 1.358, -1.428]}
                rotation={[3.117, -0.761, 0.001]}
                scale={16.667}
                userData={{ name: "Hose_R_2_10", associations: { nodes: 212 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_11"
                position={[0.828, 1.358, -1.571]}
                rotation={[3.121, -0.526, 0.007]}
                scale={16.667}
                userData={{ name: "Hose_R_2_11", associations: { nodes: 213 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_2_12"
                position={[1.076, 1.356, -1.691]}
                rotation={[3.122, -0.475, 0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_2_12", associations: { nodes: 214 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_0"
                position={[0.48, 1.045, 1.452]}
                rotation={[0.037, -1.078, -3.092]}
                scale={16.667}
                userData={{ name: "Hose_R_3_0", associations: { nodes: 215 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_1"
                position={[0.323, 1.076, 1.185]}
                rotation={[0.041, -1.134, -3.087]}
                scale={16.667}
                userData={{ name: "Hose_R_3_1", associations: { nodes: 216 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_2"
                position={[0.18, 1.073, 0.82]}
                rotation={[0.052, -1.225, -3.076]}
                scale={16.667}
                userData={{ name: "Hose_R_3_2", associations: { nodes: 217 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_3"
                position={[0.075, 1.062, 0.481]}
                rotation={[0.08, -1.351, -3.046]}
                scale={16.667}
                userData={{ name: "Hose_R_3_3", associations: { nodes: 218 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_4"
                position={[0.043, 1.042, 0.179]}
                rotation={[0.417, -1.528, -2.708]}
                scale={16.667}
                userData={{ name: "Hose_R_3_4", associations: { nodes: 219 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_5"
                position={[0.047, 1.044, -0.152]}
                rotation={[2.955, -1.476, -0.168]}
                scale={16.667}
                userData={{ name: "Hose_R_3_5", associations: { nodes: 220 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_6"
                position={[0.084, 1.043, -0.434]}
                rotation={[3.066, -1.337, -0.056]}
                scale={16.667}
                userData={{ name: "Hose_R_3_6", associations: { nodes: 221 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_7"
                position={[0.171, 1.041, -0.695]}
                rotation={[3.097, -1.171, -0.024]}
                scale={16.667}
                userData={{ name: "Hose_R_3_7", associations: { nodes: 222 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_8"
                position={[0.298, 1.046, -0.982]}
                rotation={[3.106, -1.061, -0.014]}
                scale={16.667}
                userData={{ name: "Hose_R_3_8", associations: { nodes: 223 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_9"
                position={[0.461, 1.048, -1.209]}
                rotation={[3.112, -0.952, -0.007]}
                scale={16.667}
                userData={{ name: "Hose_R_3_9", associations: { nodes: 224 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_10"
                position={[0.616, 1.062, -1.414]}
                rotation={[3.119, -0.7, 0.003]}
                scale={16.667}
                userData={{ name: "Hose_R_3_10", associations: { nodes: 225 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_11"
                position={[0.851, 1.068, -1.524]}
                rotation={[3.122, -0.478, 0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_3_11", associations: { nodes: 226 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_3_12"
                position={[1.111, 1.081, -1.611]}
                rotation={[3.122, -0.436, 0.009]}
                scale={16.667}
                userData={{ name: "Hose_R_3_12", associations: { nodes: 227 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_0"
                position={[0.481, 0.748, 1.459]}
                rotation={[0.037, -1.077, -3.092]}
                scale={16.667}
                userData={{ name: "Hose_R_4_0", associations: { nodes: 228 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_1"
                position={[0.333, 0.774, 1.194]}
                rotation={[0.04, -1.122, -3.088]}
                scale={16.667}
                userData={{ name: "Hose_R_4_1", associations: { nodes: 229 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_2"
                position={[0.173, 0.784, 0.813]}
                rotation={[0.049, -1.204, -3.079]}
                scale={16.667}
                userData={{ name: "Hose_R_4_2", associations: { nodes: 230 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_3"
                position={[0.059, 0.766, 0.494]}
                rotation={[0.074, -1.334, -3.052]}
                scale={16.667}
                userData={{ name: "Hose_R_4_3", associations: { nodes: 231 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_4"
                position={[0.022, 0.75, 0.167]}
                rotation={[0.342, -1.519, -2.783]}
                scale={16.667}
                userData={{ name: "Hose_R_4_4", associations: { nodes: 232 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_5"
                position={[0.025, 0.749, -0.166]}
                rotation={[2.989, -1.456, -0.134]}
                scale={16.667}
                userData={{ name: "Hose_R_4_5", associations: { nodes: 233 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_6"
                position={[0.072, 0.748, -0.442]}
                rotation={[3.082, -1.274, -0.04]}
                scale={16.667}
                userData={{ name: "Hose_R_4_6", associations: { nodes: 234 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_7"
                position={[0.17, 0.746, -0.696]}
                rotation={[3.104, -1.094, -0.016]}
                scale={16.667}
                userData={{ name: "Hose_R_4_7", associations: { nodes: 235 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_8"
                position={[0.348, 0.748, -0.902]}
                rotation={[3.107, -1.044, -0.013]}
                scale={16.667}
                userData={{ name: "Hose_R_4_8", associations: { nodes: 236 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_9"
                position={[0.48, 0.751, -1.182]}
                rotation={[3.113, -0.926, -0.006]}
                scale={16.667}
                userData={{ name: "Hose_R_4_9", associations: { nodes: 237 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_10"
                position={[0.653, 0.77, -1.346]}
                rotation={[3.12, -0.653, 0.004]}
                scale={16.667}
                userData={{ name: "Hose_R_4_10", associations: { nodes: 238 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_11"
                position={[0.879, 0.79, -1.459]}
                rotation={[3.122, -0.475, 0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_4_11", associations: { nodes: 239 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_4_12"
                position={[1.121, 0.796, -1.569]}
                rotation={[3.122, -0.436, 0.009]}
                scale={16.667}
                userData={{ name: "Hose_R_4_12", associations: { nodes: 240 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_0"
                position={[0.493, 0.461, 1.455]}
                rotation={[0.038, -1.093, -3.09]}
                scale={16.667}
                userData={{ name: "Hose_R_5_0", associations: { nodes: 241 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_1"
                position={[0.358, 0.49, 1.197]}
                rotation={[0.039, -1.113, -3.089]}
                scale={16.667}
                userData={{ name: "Hose_R_5_1", associations: { nodes: 242 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_2"
                position={[0.19, 0.5, 0.865]}
                rotation={[0.047, -1.187, -3.081]}
                scale={16.667}
                userData={{ name: "Hose_R_5_2", associations: { nodes: 243 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_3"
                position={[0.07, 0.484, 0.52]}
                rotation={[0.071, -1.322, -3.056]}
                scale={16.667}
                userData={{ name: "Hose_R_5_3", associations: { nodes: 244 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_4"
                position={[0.014, 0.469, 0.175]}
                rotation={[0.264, -1.504, -2.861]}
                scale={16.667}
                userData={{ name: "Hose_R_5_4", associations: { nodes: 245 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_5"
                position={[0.017, 0.468, -0.164]}
                rotation={[3.02, -1.427, -0.103]}
                scale={16.667}
                userData={{ name: "Hose_R_5_5", associations: { nodes: 246 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_6"
                position={[0.088, 0.467, -0.436]}
                rotation={[3.094, -1.195, -0.027]}
                scale={16.667}
                userData={{ name: "Hose_R_5_6", associations: { nodes: 247 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_7"
                position={[0.241, 0.466, -0.668]}
                rotation={[3.105, -1.073, -0.015]}
                scale={16.667}
                userData={{ name: "Hose_R_5_7", associations: { nodes: 248 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_8"
                position={[0.34, 0.467, -0.911]}
                rotation={[3.107, -1.048, -0.013]}
                scale={16.667}
                userData={{ name: "Hose_R_5_8", associations: { nodes: 249 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_9"
                position={[0.498, 0.466, -1.152]}
                rotation={[3.115, -0.865, -0.003]}
                scale={16.667}
                userData={{ name: "Hose_R_5_9", associations: { nodes: 250 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_10"
                position={[0.679, 0.494, -1.299]}
                rotation={[3.12, -0.631, 0.005]}
                scale={16.667}
                userData={{ name: "Hose_R_5_10", associations: { nodes: 251 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_11"
                position={[0.895, 0.507, -1.426]}
                rotation={[3.122, -0.502, 0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_5_11", associations: { nodes: 252 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_5_12"
                position={[1.132, 0.519, -1.522]}
                rotation={[3.122, -0.457, 0.009]}
                scale={16.667}
                userData={{ name: "Hose_R_5_12", associations: { nodes: 253 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_0"
                position={[0.492, 0.174, 1.469]}
                rotation={[0.038, -1.087, -3.091]}
                scale={16.667}
                userData={{ name: "Hose_R_6_0", associations: { nodes: 254 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_1"
                position={[0.37, 0.193, 1.232]}
                rotation={[0.039, -1.106, -3.089]}
                scale={16.667}
                userData={{ name: "Hose_R_6_1", associations: { nodes: 255 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_2"
                position={[0.204, 0.212, 0.888]}
                rotation={[0.047, -1.192, -3.08]}
                scale={16.667}
                userData={{ name: "Hose_R_6_2", associations: { nodes: 256 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_3"
                position={[0.09, 0.207, 0.538]}
                rotation={[0.071, -1.322, -3.055]}
                scale={16.667}
                userData={{ name: "Hose_R_6_3", associations: { nodes: 257 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_4"
                position={[0.023, 0.188, 0.194]}
                rotation={[0.207, -1.486, -2.918]}
                scale={16.667}
                userData={{ name: "Hose_R_6_4", associations: { nodes: 258 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_5"
                position={[0.019, 0.187, -0.16]}
                rotation={[3.027, -1.418, -0.096]}
                scale={16.667}
                userData={{ name: "Hose_R_6_5", associations: { nodes: 259 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_6"
                position={[0.103, 0.186, -0.43]}
                rotation={[3.097, -1.166, -0.023]}
                scale={16.667}
                userData={{ name: "Hose_R_6_6", associations: { nodes: 260 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_7"
                position={[0.235, 0.185, -0.672]}
                rotation={[3.106, -1.064, -0.014]}
                scale={16.667}
                userData={{ name: "Hose_R_6_7", associations: { nodes: 261 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_8"
                position={[0.361, 0.186, -0.907]}
                rotation={[3.109, -1.002, -0.01]}
                scale={16.667}
                userData={{ name: "Hose_R_6_8", associations: { nodes: 262 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_9"
                position={[0.52, 0.185, -1.119]}
                rotation={[3.116, -0.829, -0.002]}
                scale={16.667}
                userData={{ name: "Hose_R_6_9", associations: { nodes: 263 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_10"
                position={[0.692, 0.214, -1.264]}
                rotation={[3.119, -0.662, 0.004]}
                scale={16.667}
                userData={{ name: "Hose_R_6_10", associations: { nodes: 264 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_11"
                position={[0.91, 0.224, -1.396]}
                rotation={[3.121, -0.545, 0.007]}
                scale={16.667}
                userData={{ name: "Hose_R_6_11", associations: { nodes: 265 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_6_12"
                position={[1.115, 0.24, -1.508]}
                rotation={[3.122, -0.498, 0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_6_12", associations: { nodes: 266 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_0"
                position={[0.47, -0.093, 1.495]}
                rotation={[0.039, -1.106, -3.089]}
                scale={16.667}
                userData={{ name: "Hose_R_7_0", associations: { nodes: 267 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_1"
                position={[0.362, -0.074, 1.277]}
                rotation={[0.041, -1.13, -3.087]}
                scale={16.667}
                userData={{ name: "Hose_R_7_1", associations: { nodes: 268 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_2"
                position={[0.214, -0.069, 0.936]}
                rotation={[0.05, -1.214, -3.077]}
                scale={16.667}
                userData={{ name: "Hose_R_7_2", associations: { nodes: 269 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_3"
                position={[0.105, -0.076, 0.575]}
                rotation={[0.074, -1.333, -3.052]}
                scale={16.667}
                userData={{ name: "Hose_R_7_3", associations: { nodes: 270 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_4"
                position={[0.041, -0.093, 0.207]}
                rotation={[0.186, -1.476, -2.939]}
                scale={16.667}
                userData={{ name: "Hose_R_7_4", associations: { nodes: 271 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_5"
                position={[0.026, -0.093, -0.161]}
                rotation={[3.01, -1.437, -0.113]}
                scale={16.667}
                userData={{ name: "Hose_R_7_5", associations: { nodes: 272 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_6"
                position={[0.104, -0.095, -0.43]}
                rotation={[3.098, -1.161, -0.023]}
                scale={16.667}
                userData={{ name: "Hose_R_7_6", associations: { nodes: 273 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_7"
                position={[0.24, -0.096, -0.668]}
                rotation={[3.108, -1.022, -0.011]}
                scale={16.667}
                userData={{ name: "Hose_R_7_7", associations: { nodes: 274 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_8"
                position={[0.374, -0.096, -0.861]}
                rotation={[3.112, -0.93, -0.006]}
                scale={16.667}
                userData={{ name: "Hose_R_7_8", associations: { nodes: 275 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_9"
                position={[0.564, -0.092, -1.056]}
                rotation={[3.116, -0.803, -0.001]}
                scale={16.667}
                userData={{ name: "Hose_R_7_9", associations: { nodes: 276 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_10"
                position={[0.729, -0.058, -1.229]}
                rotation={[3.119, -0.712, 0.002]}
                scale={16.667}
                userData={{ name: "Hose_R_7_10", associations: { nodes: 277 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_11"
                position={[0.92, -0.05, -1.375]}
                rotation={[3.12, -0.619, 0.005]}
                scale={16.667}
                userData={{ name: "Hose_R_7_11", associations: { nodes: 278 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_7_12"
                position={[1.11, -0.034, -1.483]}
                rotation={[3.121, -0.579, 0.006]}
                scale={16.667}
                userData={{ name: "Hose_R_7_12", associations: { nodes: 279 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_0"
                position={[0.456, -0.376, 1.504]}
                rotation={[0.042, -1.138, -3.086]}
                scale={16.667}
                userData={{ name: "Hose_R_8_0", associations: { nodes: 280 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_1"
                position={[0.363, -0.364, 1.288]}
                rotation={[0.044, -1.164, -3.084]}
                scale={16.667}
                userData={{ name: "Hose_R_8_1", associations: { nodes: 281 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_2"
                position={[0.227, -0.353, 0.952]}
                rotation={[0.053, -1.236, -3.074]}
                scale={16.667}
                userData={{ name: "Hose_R_8_2", associations: { nodes: 282 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_3"
                position={[0.118, -0.359, 0.596]}
                rotation={[0.079, -1.347, -3.047]}
                scale={16.667}
                userData={{ name: "Hose_R_8_3", associations: { nodes: 283 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_4"
                position={[0.062, -0.374, 0.217]}
                rotation={[0.192, -1.479, -2.933]}
                scale={16.667}
                userData={{ name: "Hose_R_8_4", associations: { nodes: 284 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_5"
                position={[0.041, -0.374, -0.158]}
                rotation={[2.968, -1.47, -0.156]}
                scale={16.667}
                userData={{ name: "Hose_R_8_5", associations: { nodes: 285 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_6"
                position={[0.104, -0.376, -0.436]}
                rotation={[3.1, -1.134, -0.02]}
                scale={16.667}
                userData={{ name: "Hose_R_8_6", associations: { nodes: 286 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_7"
                position={[0.27, -0.377, -0.651]}
                rotation={[3.11, -0.989, -0.009]}
                scale={16.667}
                userData={{ name: "Hose_R_8_7", associations: { nodes: 287 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_8"
                position={[0.396, -0.376, -0.862]}
                rotation={[3.113, -0.924, -0.006]}
                scale={16.667}
                userData={{ name: "Hose_R_8_8", associations: { nodes: 288 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_9"
                position={[0.577, -0.37, -1.044]}
                rotation={[3.117, -0.781, 0]}
                scale={16.667}
                userData={{ name: "Hose_R_8_9", associations: { nodes: 289 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_10"
                position={[0.752, -0.341, -1.194]}
                rotation={[3.118, -0.725, 0.002]}
                scale={16.667}
                userData={{ name: "Hose_R_8_10", associations: { nodes: 290 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_11"
                position={[0.931, -0.339, -1.344]}
                rotation={[3.119, -0.686, 0.003]}
                scale={16.667}
                userData={{ name: "Hose_R_8_11", associations: { nodes: 291 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_8_12"
                position={[1.095, -0.315, -1.488]}
                rotation={[3.119, -0.686, 0.003]}
                scale={16.667}
                userData={{ name: "Hose_R_8_12", associations: { nodes: 292 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_0"
                position={[0.443, -0.647, 1.489]}
                rotation={[0.042, -1.143, -3.086]}
                scale={16.667}
                userData={{ name: "Hose_R_9_0", associations: { nodes: 293 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_1"
                position={[0.355, -0.648, 1.296]}
                rotation={[0.045, -1.175, -3.082]}
                scale={16.667}
                userData={{ name: "Hose_R_9_1", associations: { nodes: 294 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_2"
                position={[0.231, -0.635, 0.948]}
                rotation={[0.056, -1.257, -3.07]}
                scale={16.667}
                userData={{ name: "Hose_R_9_2", associations: { nodes: 295 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_3"
                position={[0.129, -0.644, 0.596]}
                rotation={[0.088, -1.371, -3.038]}
                scale={16.667}
                userData={{ name: "Hose_R_9_3", associations: { nodes: 296 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_4"
                position={[0.084, -0.66, 0.232]}
                rotation={[0.264, -1.504, -2.86]}
                scale={16.667}
                userData={{ name: "Hose_R_9_4", associations: { nodes: 297 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_5"
                position={[0.063, -0.657, -0.141]}
                rotation={[2.892, -1.5, -0.232]}
                scale={16.667}
                userData={{ name: "Hose_R_9_5", associations: { nodes: 298 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_6"
                position={[0.109, -0.657, -0.425]}
                rotation={[3.097, -1.165, -0.023]}
                scale={16.667}
                userData={{ name: "Hose_R_9_6", associations: { nodes: 299 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_7"
                position={[0.306, -0.658, -0.62]}
                rotation={[3.112, -0.951, -0.007]}
                scale={16.667}
                userData={{ name: "Hose_R_9_7", associations: { nodes: 300 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_8"
                position={[0.409, -0.657, -0.85]}
                rotation={[3.113, -0.91, -0.005]}
                scale={16.667}
                userData={{ name: "Hose_R_9_8", associations: { nodes: 301 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_9"
                position={[0.596, -0.65, -1.034]}
                rotation={[3.116, -0.804, -0.001]}
                scale={16.667}
                userData={{ name: "Hose_R_9_9", associations: { nodes: 302 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_10"
                position={[0.765, -0.626, -1.213]}
                rotation={[3.118, -0.743, 0.001]}
                scale={16.667}
                userData={{ name: "Hose_R_9_10", associations: { nodes: 303 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_11"
                position={[0.949, -0.62, -1.348]}
                rotation={[3.118, -0.72, 0.002]}
                scale={16.667}
                userData={{ name: "Hose_R_9_11", associations: { nodes: 304 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_9_12"
                position={[1.083, -0.599, -1.476]}
                rotation={[3.118, -0.751, 0.001]}
                scale={16.667}
                userData={{ name: "Hose_R_9_12", associations: { nodes: 305 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_0"
                position={[0.44, -0.97, 1.49]}
                rotation={[0.04, -1.121, -3.088]}
                scale={16.667}
                userData={{ name: "Hose_R_10_0", associations: { nodes: 306 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_1"
                position={[0.347, -0.973, 1.284]}
                rotation={[0.045, -1.175, -3.082]}
                scale={16.667}
                userData={{ name: "Hose_R_10_1", associations: { nodes: 307 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_2"
                position={[0.218, -0.957, 0.949]}
                rotation={[0.064, -1.292, -3.063]}
                scale={16.667}
                userData={{ name: "Hose_R_10_2", associations: { nodes: 308 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_3"
                position={[0.145, -0.948, 0.589]}
                rotation={[0.105, -1.404, -3.02]}
                scale={16.667}
                userData={{ name: "Hose_R_10_3", associations: { nodes: 309 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_4"
                position={[0.113, -0.959, 0.25]}
                rotation={[0.77, -1.546, -2.354]}
                scale={16.667}
                userData={{ name: "Hose_R_10_4", associations: { nodes: 310 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_5"
                position={[0.131, -0.953, -0.116]}
                rotation={[2.891, -1.5, -0.233]}
                scale={16.667}
                userData={{ name: "Hose_R_10_5", associations: { nodes: 311 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_6"
                position={[0.124, -0.944, -0.414]}
                rotation={[3.076, -1.303, -0.046]}
                scale={16.667}
                userData={{ name: "Hose_R_10_6", associations: { nodes: 312 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_7"
                position={[0.247, -0.939, -0.647]}
                rotation={[3.111, -0.971, -0.008]}
                scale={16.667}
                userData={{ name: "Hose_R_10_7", associations: { nodes: 313 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_8"
                position={[0.441, -0.938, -0.814]}
                rotation={[3.116, -0.834, -0.002]}
                scale={16.667}
                userData={{ name: "Hose_R_10_8", associations: { nodes: 314 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_9"
                position={[0.624, -0.93, -1.019]}
                rotation={[3.116, -0.815, -0.001]}
                scale={16.667}
                userData={{ name: "Hose_R_10_9", associations: { nodes: 315 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_10"
                position={[0.798, -0.908, -1.21]}
                rotation={[3.117, -0.768, 0.001]}
                scale={16.667}
                userData={{
                  name: "Hose_R_10_10",
                  associations: { nodes: 316 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_11"
                position={[0.957, -0.889, -1.361]}
                rotation={[3.118, -0.729, 0.002]}
                scale={16.667}
                userData={{
                  name: "Hose_R_10_11",
                  associations: { nodes: 317 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_10_12"
                position={[1.079, -0.871, -1.473]}
                rotation={[3.118, -0.742, 0.001]}
                scale={16.667}
                userData={{
                  name: "Hose_R_10_12",
                  associations: { nodes: 318 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_0"
                position={[0.402, -1.311, 1.503]}
                rotation={[0.038, -1.091, -3.091]}
                scale={16.667}
                userData={{ name: "Hose_R_11_0", associations: { nodes: 319 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_1"
                position={[0.299, -1.296, 1.283]}
                rotation={[0.046, -1.181, -3.082]}
                scale={16.667}
                userData={{ name: "Hose_R_11_1", associations: { nodes: 320 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_2"
                position={[0.216, -1.28, 0.932]}
                rotation={[0.074, -1.331, -3.053]}
                scale={16.667}
                userData={{ name: "Hose_R_11_2", associations: { nodes: 321 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_3"
                position={[0.152, -1.248, 0.58]}
                rotation={[0.125, -1.43, -3.001]}
                scale={16.667}
                userData={{ name: "Hose_R_11_3", associations: { nodes: 322 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_4"
                position={[0.121, -1.26, 0.265]}
                rotation={[2.221, -1.549, -0.903]}
                scale={16.667}
                userData={{ name: "Hose_R_11_4", associations: { nodes: 323 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_5"
                position={[0.16, -1.248, -0.089]}
                rotation={[2.971, -1.468, -0.152]}
                scale={16.667}
                userData={{ name: "Hose_R_11_5", associations: { nodes: 324 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_6"
                position={[0.187, -1.228, -0.4]}
                rotation={[3.041, -1.396, -0.081]}
                scale={16.667}
                userData={{ name: "Hose_R_11_6", associations: { nodes: 325 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_7"
                position={[0.238, -1.219, -0.653]}
                rotation={[3.102, -1.109, -0.018]}
                scale={16.667}
                userData={{ name: "Hose_R_11_7", associations: { nodes: 326 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_8"
                position={[0.404, -1.214, -0.862]}
                rotation={[3.115, -0.862, -0.003]}
                scale={16.667}
                userData={{ name: "Hose_R_11_8", associations: { nodes: 327 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_9"
                position={[0.583, -1.209, -1.035]}
                rotation={[3.117, -0.795, 0]}
                scale={16.667}
                userData={{ name: "Hose_R_11_9", associations: { nodes: 328 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_10"
                position={[0.762, -1.181, -1.219]}
                rotation={[3.118, -0.742, 0.001]}
                scale={16.667}
                userData={{
                  name: "Hose_R_11_10",
                  associations: { nodes: 329 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_11"
                position={[0.955, -1.146, -1.381]}
                rotation={[3.119, -0.693, 0.003]}
                scale={16.667}
                userData={{
                  name: "Hose_R_11_11",
                  associations: { nodes: 330 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_11_12"
                position={[1.062, -1.139, -1.473]}
                rotation={[3.119, -0.682, 0.003]}
                scale={16.667}
                userData={{
                  name: "Hose_R_11_12",
                  associations: { nodes: 331 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_0"
                position={[0.366, -1.663, 1.512]}
                rotation={[0.037, -1.08, -3.091]}
                scale={16.667}
                userData={{ name: "Hose_R_12_0", associations: { nodes: 332 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_1"
                position={[0.263, -1.649, 1.302]}
                rotation={[0.047, -1.186, -3.081]}
                scale={16.667}
                userData={{ name: "Hose_R_12_1", associations: { nodes: 333 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_2"
                position={[0.166, -1.645, 0.921]}
                rotation={[0.079, -1.346, -3.048]}
                scale={16.667}
                userData={{ name: "Hose_R_12_2", associations: { nodes: 334 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_3"
                position={[0.124, -1.622, 0.59]}
                rotation={[0.135, -1.441, -2.99]}
                scale={16.667}
                userData={{ name: "Hose_R_12_3", associations: { nodes: 335 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_4"
                position={[0.119, -1.625, 0.282]}
                rotation={[2.406, -1.545, -0.718]}
                scale={16.667}
                userData={{ name: "Hose_R_12_4", associations: { nodes: 336 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_5"
                position={[0.138, -1.617, -0.079]}
                rotation={[2.998, -1.448, -0.125]}
                scale={16.667}
                userData={{ name: "Hose_R_12_5", associations: { nodes: 337 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_6"
                position={[0.19, -1.611, -0.38]}
                rotation={[3.036, -1.404, -0.087]}
                scale={16.667}
                userData={{ name: "Hose_R_12_6", associations: { nodes: 338 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_7"
                position={[0.251, -1.605, -0.632]}
                rotation={[3.094, -1.195, -0.027]}
                scale={16.667}
                userData={{ name: "Hose_R_12_7", associations: { nodes: 339 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_8"
                position={[0.365, -1.595, -0.891]}
                rotation={[3.112, -0.932, -0.006]}
                scale={16.667}
                userData={{ name: "Hose_R_12_8", associations: { nodes: 340 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_9"
                position={[0.53, -1.554, -1.1]}
                rotation={[3.117, -0.789, 0]}
                scale={16.667}
                userData={{ name: "Hose_R_12_9", associations: { nodes: 341 } }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_10"
                position={[0.729, -1.44, -1.243]}
                rotation={[3.119, -0.713, 0.002]}
                scale={16.667}
                userData={{
                  name: "Hose_R_12_10",
                  associations: { nodes: 342 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_11"
                position={[0.944, -1.288, -1.389]}
                rotation={[3.12, -0.658, 0.004]}
                scale={16.667}
                userData={{
                  name: "Hose_R_12_11",
                  associations: { nodes: 343 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
              <group
                name="Hose_R_12_12"
                position={[1.054, -1.207, -1.455]}
                rotation={[3.12, -0.637, 0.005]}
                scale={16.667}
                userData={{
                  name: "Hose_R_12_12",
                  associations: { nodes: 344 },
                }}
              >
                      {debug && <primitive object={new THREE.AxesHelper(0.02)} />}
                  </group>
            </group>
          </mesh>
        </group>
      </group>
      {props.children}
    </group>
  );
}

useGLTF.preload("./models/Weste.glb");

useGLTF.preload("./models/MP_F.glb");
useGLTF.preload("./models/MP_O.glb");
useGLTF.preload("./models/MP_Z.glb");
useGLTF.preload("./models/MP_F_V1.glb");
useGLTF.preload("./models/MP_O_V1.glb");
useGLTF.preload("./models/MP_Z_V1.glb");
useGLTF.preload("./models/P_1.glb");
useGLTF.preload("./models/P_2.glb");
useGLTF.preload("./models/P_3.glb");
useGLTF.preload("./models/S_1.glb");
useGLTF.preload("./models/S_2.glb");
useGLTF.preload("./models/S_3.glb");
useGLTF.preload("./models/NO.glb");
useGLTF.preload("./models/Y.glb");
useGLTF.preload("./models/C.glb");