/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import { useMemo, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { useFrame, useGraph, useLoader } from "@react-three/fiber";
import { ModuleLibraryData, ModuleState, VisualizationType } from "../store/interfaces";
import { CARGO_BONES_COUNT_X, CARGO_BONES_COUNT_Y, SKETCH_ELEMENT_HEIGHT, SKETCH_ELEMENT_WIDTH } from "../helpers/constants";
import { MathUtils } from "three";
//@ts-ignore
import {clone as CloneScene} from 'three/examples/jsm/utils/SkeletonUtils';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

type GroupProps = JSX.IntrinsicElements['group'];

interface ModuleProps extends GroupProps {
  side: string;
  state: ModuleState;
  library: ModuleLibraryData;
  visualization?: VisualizationType;
}

type GLTFResult = GLTF & {
  nodes: {
    Würfel_1: THREE.SkinnedMesh;
    Tasche_5_5: THREE.Bone;
    Tasche_5_4: THREE.Bone;
    Tasche_5_3: THREE.Bone;
    Tasche_5_2: THREE.Bone;
    Tasche_5_1: THREE.Bone;
    Tasche_5_0: THREE.Bone;
    Tasche_4_5: THREE.Bone;
    Tasche_4_4: THREE.Bone;
    Tasche_4_3: THREE.Bone;
    Tasche_4_2: THREE.Bone;
    Tasche_4_1: THREE.Bone;
    Tasche_4_0: THREE.Bone;
    Tasche_3_5: THREE.Bone;
    Tasche_3_4: THREE.Bone;
    Tasche_3_3: THREE.Bone;
    Tasche_3_2: THREE.Bone;
    Tasche_3_1: THREE.Bone;
    Tasche_3_0: THREE.Bone;
    Tasche_2_5: THREE.Bone;
    Tasche_2_4: THREE.Bone;
    Tasche_2_3: THREE.Bone;
    Tasche_2_2: THREE.Bone;
    Tasche_2_1: THREE.Bone;
    Tasche_2_0: THREE.Bone;
    Tasche_1_5: THREE.Bone;
    Tasche_1_4: THREE.Bone;
    Tasche_1_3: THREE.Bone;
    Tasche_1_2: THREE.Bone;
    Tasche_1_1: THREE.Bone;
    Tasche_1_0: THREE.Bone;
    Tasche_0_5: THREE.Bone;
    Tasche_0_4: THREE.Bone;
    Tasche_0_3: THREE.Bone;
    Tasche_0_2: THREE.Bone;
    Tasche_0_1: THREE.Bone;
    Tasche_0_0: THREE.Bone;
  };
  materials: {
    Mat: THREE.MeshStandardMaterial;
  };
};

const BONES_COUNT_X: number = 6;
const BONES_COUNT_Y: number = 6;

function interpolateOrientation(target: THREE.Object3D, relPosX: number, relPosY: number, side: string, scene: THREE.Scene): boolean {
  let x0 = Math.floor(relPosX);
  let y0 = Math.floor(relPosY);
  let x1 = Math.ceil(relPosX);
  let y1 = Math.ceil(relPosY);

  x0 = MathUtils.clamp(x0, 0, (CARGO_BONES_COUNT_X - 1));
  y0 = MathUtils.clamp(y0, 0, (CARGO_BONES_COUNT_Y - 1));
  x1 = MathUtils.clamp(x1, 0, (CARGO_BONES_COUNT_X - 1));
  y1 = MathUtils.clamp(y1, 0, (CARGO_BONES_COUNT_Y - 1));

  //console.log(`Interpolate ${x0} ${x1} ${y0} ${y1}`);

  let boneName00 = `Hose_${side === 'left' ? 'L' : 'R'}_${y0}_${x0}`;
  let cargoBone00 = scene.getObjectByName(boneName00);
  if(cargoBone00 === undefined) {
    console.log(`Bone ${boneName00} not found`)
    return false;
  }
  let boneName01 = `Hose_${side === 'left' ? 'L' : 'R'}_${y0}_${x1}`;
  let cargoBone01 = scene.getObjectByName(boneName01);
  if(cargoBone01 === undefined) {
    console.log(`Bone ${boneName01} not found`)
    return false;
  }
  let boneName10 = `Hose_${side === 'left' ? 'L' : 'R'}_${y1}_${x0}`;
  let cargoBone10 = scene.getObjectByName(boneName10);
  if(cargoBone10 === undefined) {
    console.log(`Bone ${boneName10} not found`)
    return false;
  }
  let boneName11 = `Hose_${side === 'left' ? 'L' : 'R'}_${y1}_${x1}`;
  let cargoBone11 = scene.getObjectByName(boneName11);
  if(cargoBone11 === undefined) {
    console.log(`Bone ${boneName11} not found`)
    return false;
  }

  const position0 = new THREE.Vector3();
  const position1 = new THREE.Vector3();
  const position00 = new THREE.Vector3();
  const position01 = new THREE.Vector3();
  const position10 = new THREE.Vector3();
  const position11 = new THREE.Vector3();

  cargoBone00.getWorldPosition(position00);
  cargoBone01.getWorldPosition(position01);
  cargoBone10.getWorldPosition(position10);
  cargoBone11.getWorldPosition(position11);

  const rotation0 = new THREE.Quaternion();
  const rotation1 = new THREE.Quaternion();
  const rotation00 = new THREE.Quaternion();
  const rotation01 = new THREE.Quaternion();
  const rotation10 = new THREE.Quaternion();
  const rotation11 = new THREE.Quaternion();

  cargoBone00.getWorldQuaternion(rotation00);
  cargoBone01.getWorldQuaternion(rotation01);
  cargoBone10.getWorldQuaternion(rotation10);
  cargoBone11.getWorldQuaternion(rotation11);


  let alphaX = MathUtils.clamp(relPosX - x0, 0, 1);
  let alphaY = MathUtils.clamp(relPosY - y0, 0, 1);

  position0.lerpVectors(position00, position01, alphaX);
  position1.lerpVectors(position10, position11, alphaX);
  target.position.lerpVectors(position0, position1, alphaY);

  rotation0.slerpQuaternions(rotation00, rotation01, alphaX);
  rotation1.slerpQuaternions(rotation10, rotation11, alphaX);
  target.quaternion.slerpQuaternions(rotation0, rotation1, alphaY);

  if(side === 'left') {
    target.scale.x = -1;
  } else {
    target.scale.x = 1;
  }

  const normal = new THREE.Vector3();
  target.getWorldDirection(normal);
  target.position.add(normal.multiplyScalar(0.0032));
  
  return true;
}

export default function Model(props: ModuleProps) {
  const [isPositioned, setIsPositioned] = useState(false);
  const [activeSide, setActiveSide] = useState('');
  const [activeVisualization, setActiveVisualization] = useState<VisualizationType|undefined>(undefined);

  const group = useRef<THREE.Group>(null);
  const { scene } = useGLTF(`./models/${props.state.article}.glb`) as any;
  const colorMap = useLoader(TextureLoader, './models/Stoffmuster.jpg')
  const normalMap = useLoader(TextureLoader, './models/Stoffmuster_normal.jpg')

  const colorMapVest = useLoader(TextureLoader, './models/Stoffmuster_Weste.jpg')
  const normalMapVest = useLoader(TextureLoader, './models/Stoffmuster_Weste_normal.jpg')


  const clone = useMemo(() => CloneScene(scene), [scene]);
  const { nodes, materials} = useGraph(clone) as any as GLTFResult;
  
  if(activeSide !== props.side || activeVisualization !== props.visualization) {
    setActiveSide(props.side);
    setActiveVisualization(props.visualization);
    setIsPositioned(false);
  }

  useFrame((state, delta) => {
    let posSuccess = true;

    if(!isPositioned) {
      for (let y = 0; y < BONES_COUNT_Y; y++) {
        for (let x = 0; x < BONES_COUNT_X; x++) {
          let moduleBone = (nodes as any)[`Tasche_${y}_${x}`];
          if(moduleBone !== undefined) {
            let alphaX = x / (BONES_COUNT_X - 1);
            let alphaY = y / (BONES_COUNT_Y - 1);

            let relPosX: number;            
            // Todo(Daniel): Left and right has been changed for now because of mismatch on documentation!
            if(props.side === 'right') {
              relPosX = ((props.state.left + props.state.width * alphaX) / (SKETCH_ELEMENT_WIDTH - 1)) * (CARGO_BONES_COUNT_X - 1);            
              relPosX = (CARGO_BONES_COUNT_X - 1) - relPosX;
            } else {
              relPosX = ((props.state.left + props.state.width - props.state.width * alphaX) / (SKETCH_ELEMENT_WIDTH - 1)) * (CARGO_BONES_COUNT_X - 1);       
            }
            let relPosY = ((props.state.top + props.state.height * alphaY) / (SKETCH_ELEMENT_HEIGHT - 1)) * (CARGO_BONES_COUNT_Y - 1);

            if(!interpolateOrientation(moduleBone, relPosX, relPosY, props.side, state.scene)) {
              posSuccess = false;
              break;
            }

            if(props.state.article === 'Y' || props.state.article === 'C') {
              moduleBone.scale.z = 0.5;
            } else {
              moduleBone.scale.z = 1.0;
            }
          } else {
            console.log("ASODPOQAWDA");
          }
        }    
        if(!posSuccess) {
          break;
        }
      }
      if(posSuccess) {
        setIsPositioned(true);
      }
    }
  });

  const textureSizeCm = 3.5;

  materials.Mat = materials.Mat.clone();

  let uvXRepeat = props.state.width / textureSizeCm;
  let uvYRepeat = props.state.height / textureSizeCm;
  materials.Mat.userData.albedoRepeatX = {value: uvXRepeat};
  materials.Mat.userData.albedoRepeatY = {value: uvYRepeat};
  materials.Mat.userData.normalRepeatX = {value: uvXRepeat * 0.3};
  materials.Mat.userData.normalRepeatY = {value: uvYRepeat * 0.3};
  materials.Mat.userData.normalMap2 = {value: (props.visualization === VisualizationType.Shorts) ? normalMap : normalMapVest};
  materials.Mat.userData.normalMapStrength = {value: props.side === 'left' ? -1 : 1};
  normalMap.wrapS = THREE.RepeatWrapping;
  normalMap.wrapT = THREE.RepeatWrapping;
  normalMapVest.wrapS = THREE.RepeatWrapping;
  normalMapVest.wrapT = THREE.RepeatWrapping;
  materials.Mat.map = (props.visualization === VisualizationType.Shorts) ? colorMap : colorMapVest;
  materials.Mat.map.wrapS = THREE.RepeatWrapping;
  materials.Mat.map.wrapT = THREE.RepeatWrapping;
  materials.Mat.map.encoding = THREE.sRGBEncoding;
  materials.Mat.aoMapIntensity = 2.0;
  materials.Mat.roughness = 0.8;

  //materials.Mat.lightMap = materials.Mat.aoMap;
  //materials.Mat.aoMap = null;
  
  if(props.side === 'left') {
    materials.Mat.side = THREE.BackSide;
  } else {
    materials.Mat.side = THREE.FrontSide;
  }

  materials.Mat.onBeforeCompile = (shader, renderer) => {
    shader.uniforms.albedoRepeatX = materials.Mat.userData.albedoRepeatX;
    shader.uniforms.albedoRepeatY = materials.Mat.userData.albedoRepeatY;
    shader.uniforms.normalRepeatX = materials.Mat.userData.normalRepeatX;
    shader.uniforms.normalRepeatY = materials.Mat.userData.normalRepeatY;
    shader.uniforms.normalMap2 = materials.Mat.userData.normalMap2;
    shader.uniforms.normalMapStrength = materials.Mat.userData.normalMapStrength;

    shader.fragmentShader = 
    shader.fragmentShader.replace( 
      '#include <uv_pars_fragment>', 
      `
        uniform float albedoRepeatX;
        uniform float albedoRepeatY;
        uniform float normalRepeatX;
        uniform float normalRepeatY;
        uniform float normalMapStrength;
        uniform sampler2D normalMap2;
        #if ( defined( USE_UV ) && ! defined( UVS_VERTEX_ONLY ) )
          varying vec2 vUv;
        #endif
      `
    ).replace( 
      '#include <map_fragment>', 
      `
        vec2 worldUV = vUv * vec2(albedoRepeatX, albedoRepeatY);
        vec2 worldUVNormal = vUv * vec2(normalRepeatX, normalRepeatY);
        vec4 sampledDiffuseColor = texture2D( map, worldUV );
        diffuseColor *= sampledDiffuseColor;
      `
    ).replace( 
      '#include <aomap_fragment>', 
      `
        #ifdef USE_AOMAP
          // reads channel R, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
          float ambientOcclusion = ( texture2D( aoMap, vUv ).r - 1.0 ) * aoMapIntensity + 1.0;
          //reflectedLight.indirectDiffuse *= ambientOcclusion;
          reflectedLight.directDiffuse *= ambientOcclusion;
          #if defined( USE_ENVMAP ) && defined( STANDARD )
              float dotNV = saturate( dot( geometry.normal, geometry.viewDir ) );
              //reflectedLight.indirectSpecular *= computeSpecularOcclusion( dotNV, ambientOcclusion, material.roughness );
          #endif

          //reflectedLight.directDiffuse = normal;
      #endif
      `
    ).replace( 
      '#include <normal_fragment_maps>', 
      `
        #ifdef OBJECTSPACE_NORMALMAP
          normal = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0; // overrides both flatShading and attribute normals
          #ifdef FLIP_SIDED
            normal = - normal;
          #endif
          #ifdef DOUBLE_SIDED
            normal = normal * faceDirection;
          #endif
          normal = normalize( normalMatrix * normal );
        #elif defined( TANGENTSPACE_NORMALMAP )
          vec3 mapN = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0;
          mapN.xy *= 1.4;
          vec3 mapN2 = texture2D( normalMap2, worldUVNormal ).xyz * 2.0 - 1.0;
          //mapN2.xy *= normalMapStrength;
          #ifdef USE_TANGENT
            normal = normalize( vTBN * mapN + vTBN * mapN2);
          #else
            vec3 originalNormal = normal;
            normal = perturbNormal2Arb( - vViewPosition, normal, mapN, faceDirection );
            vec3 normal2 = perturbNormal2Arb( - vViewPosition, originalNormal, mapN2, faceDirection );
            normal = normalize(normal + normal2);
          #endif
        #elif defined( USE_BUMPMAP )
          normal = perturbNormalArb( - vViewPosition, normal, dHdxy_fwd(), faceDirection );
        #endif
        normal = normalize(normalMapStrength * normal);
      `
    );
  };


  const debug = false;

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="Null">
          <primitive object={nodes.Tasche_5_5}>
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_5_4} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_5_3} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_5_2} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_5_1} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_5_0} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_4_5} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_4_4} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_4_3} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_4_2} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_4_1} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_4_0} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_3_5} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_3_4} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_3_3} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_3_2} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_3_1} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_3_0} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_2_5} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_2_4} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_2_3} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_2_2} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_2_1} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_2_0} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_1_5} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_1_4} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_1_3} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_1_2} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_1_1} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_1_0} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_0_5} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_0_4} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_0_3} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_0_2} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_0_1} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <primitive object={nodes.Tasche_0_0} >
              {debug && <primitive object={new THREE.AxesHelper(0.01)} />}
          </primitive>
          <group name="Würfel">
            <skinnedMesh
              name="Würfel_1"
              geometry={nodes.Würfel_1.geometry}
              material={materials.Mat}
              skeleton={nodes.Würfel_1.skeleton}
              frustumCulled={false}
            >
            </skinnedMesh>
          </group>
        </group>
      </group>
    </group>
  );
}
